<!-- 我的文献 -->
<template>
  <div class="myfile">
    <navBar></navBar>
    <div class="myfile-container">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column prop="file_id" label="编号" />
        <el-table-column prop="file_name" label="文献名称" width="500">
          <template slot-scope="scope">
            <a
              @click="jump_detail(scope.$index, scope.row)"
              style="color: blue; cursor: pointer"
              >{{ scope.row.file_name }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="percentage" label="处理进度">
          <template slot-scope="scope">
            <el-progress
              :text-inside="true"
              :stroke-width="26"
              :percentage="scope.row.percentage"
            ></el-progress>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleView(scope.$index, scope.row)"
              >查 看</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { getCookie } from "../assets/js/cookie.js"

export default {
  data () {
    return {
      tableData: [],
    }
  },
  methods: {
    getData () {
      const that = this
      let token = getCookie("token")
      if (token == "") {
        alert("还未登录")
        return
      }

      axios({
        method: "get",
        url: "http://81.70.100.28:5000/api/v1.0/user/processes",
        headers: {
          token: token,
        },
      }).then(function (response) {
        if (response.data.errno == "0") {
          console.log(response.data.data)
          that.tableData = response.data.data
        } else {
          alert("请求失败！")
        }
      })
    },
    handleView (index, row) {
      console.log(row["file_id"])
      this.$router.push({
        path: "/docs/detail",
        query: {
          file_id: row["file_id"],
        },
      })
    },
    handleDelete (index, row) {
      console.log(index, row)
      const that = this
      let token = getCookie("token")
      if (token == "") {
        alert("还未登录")
        return
      }
      axios({
        method: "put",
        url:
          "http://81.70.100.28:5000/api/v1.0/process/" +
          row["process_id"] +
          "/delete",
        headers: {
          token: token,
        },
      }).then(function (response) {
        if (response.data.errno == "0") {
          console.log(response.data.data)
          that.tableData.splice(index, 1)
        } else {
          alert("删除失败！")
        }
      })
    },
    jump_detail (index, row) {
      console.log(row["file_id"])
      this.$router.push({
        path: "docs/detail",
        query: {
          file_id: row["file_id"],
        },
      })
    },
  },
  created () {
    this.getData()
  },
};
</script>
<style scoped>
.myfile-container {
  margin: 20px auto;
  width: 80%;
  text-align: center;
}
</style>
