<!--  -->
<template>
  <div class="login" clearfix>
    <navBar></navBar>
    <div class="login-wrap">
      <el-row type="flex" justify="center">
        <el-form ref="loginForm" :model="user" status-icon label-width="80px">
          <h3>登 录</h3>
          <hr />
          <el-form-item prop="username" label="手机号">
            <el-input
              v-model="user.username"
              placeholder="请输入手机号"
              prefix-icon
            ></el-input>
          </el-form-item>
          <el-form-item id="password" prop="password" label="密码">
            <el-input
              v-model="user.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <router-link to="/account/register">注册账号</router-link>
        </el-form>
      </el-row>
      <el-button type="primary" icon="el-icon-upload" @click="doLogin()"
        >登 录</el-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { setCookie } from "../../assets/js/cookie"

export default {
  name: "login",
  data () {
    return {
      user: {
        username: "",
        password: "",
      },
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    /*页面挂载获取 cookie，如果存在 username 的 cookie，则跳转到主页，不需登录*/
  },
  methods: {
    doLogin () {
      const that = this
      if (!this.user.username) {
        this.$message.error("请输入用户名")
        // alert("请输入用户名")
        return
      } else if (!this.user.password) {
        this.$message.error("请输入密码")
        return
      } else {
        let data = { mobile: this.user.username, password: this.user.password }
        console.log(data)
        axios({
          method: "post",
          url: "http://81.70.100.28:5000/api/v1.0/login",
          data: data,
        }).then(function (response) {
          if (response.data.errno == "0") {
            setCookie("token", response.data.token)
            setTimeout(
              function () {
                that.$router.push("/home")
              }.bind(this),
              1000
            )
            window.sessionStorage.setItem("username", that.user.username)
            that.$router.push({ path: "/home" })
          } else {
            alert("您输入的用户名或密码错误!")
          }
        })
      }
    },
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.login {
  width: 100%;
  height: 740px;
  overflow: hidden;
}
.login-wrap {
  background-color: #fff;
  width: 400px;
  height: 340px;
  margin: 215px auto;
  overflow: hidden;
  padding-top: 10px;
  line-height: 40px;
  border-radius: 10px;
}
#password {
  margin-bottom: 5px;
}
.el-form {
  font-size: 24px;
}
hr {
  background-color: #444;
  margin: 20px auto;
}
a {
  text-decoration: none;
  color: #aaa;
  font-size: 15px;
  margin: 20px 10px;
}
a:hover {
  color: coral;
}
.el-button {
  width: 70%;
  background-color: #42b983;
}
</style>
