<!--  -->
<template>
  <div class="modify" clearfix>
    <navBar></navBar>
    <div class="modify-wrap">
      <el-row type="flex" justify="center">
        <el-form ref="modifyForm" :model="user" status-icon label-width="80px">
          <h3>修改密码</h3>
          <hr />
          <el-form-item prop="username" label="手机号">
            <el-input
              v-model="user.username"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" label="设置密码">
            <el-input
              v-model="user.password"
              show-password
              placeholder="请输入新密码密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="repassword" label="确认密码">
            <el-input
              v-model="user.repassword"
              show-password
              placeholder="请再次输入新密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <el-button type="primary" icon @click="doModify()"
        >修改密码账号</el-button
      >
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "register",
  data () {
    return {
      user: {
        username: "",
        password: "",
        repassword: "",
      },
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
  methods: {
    doModify () {
      const that = this
      if (!this.user.username) {
        this.$message.error("请输入手机号！")
        return
      } else if (!this.user.password) {
        this.$message.error("请输入新密码！")
        return
      } else if (!this.user.repassword) {
        this.$message.error("请再次输入新密码！")
      } else {
        // this.$router.push({ path: "/" }); //无需向后台提交数据，方便前台调试
        let data = {
          mobile: this.user.username,
          password: this.user.password,
        }
        axios({
          method: "post",
          url: "http://81.70.100.28:5000/api/v1.0/modify",
          data: data,
        }).then(function (response) {
          if (response.data.errno == "0") {
            window.sessionStorage.setItem("username", that.user.username)
            that.$router.push({ path: "/home" })
          } else {
            alert("修改失败！")
          }
        })
      }
    },
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.modify {
  width: 100%;
  height: 740px;
  overflow: hidden;
}
.modify-wrap {
  background-color: #fff;
  width: 400px;
  height: 370px;
  margin: 215px auto;
  overflow: hidden;
  padding-top: 10px;
  line-height: 40px;
  border-radius: 10px;
}
#password {
  margin-bottom: 5px;
}
.el-form {
  color: #42b983;
  font-size: 24px;
}
hr {
  background-color: #444;
  margin: 20px auto;
}
a {
  text-decoration: none;
  color: #aaa;
  font-size: 15px;
  margin: 20px 10px;
}
a:hover {
  color: coral;
}
.el-button {
  width: 70%;
  background-color: #42b983;
}
</style>
