<!--  -->
<template>
  <div class="read">
    <navBar></navBar>
    <div class="read-container">
      <el-row>
        <el-col :span="12">
          <div class="origin-doc">
            <div class="origin-doc-head">
              <el-row>
                <el-col :span="24">
                  <h2>原始文档</h2>
                </el-col>
              </el-row>
            </div>

            <div class="block" style="background-color: #fff">
              <el-carousel
                :autoplay="false"
                @change="handleCurrentChange"
                :height="imageHeight"
              >
                <el-carousel-item
                  v-for="(item, index) in imageData"
                  :key="index"
                >
                  <img
                    :src="item"
                    style="width: calc(100% - 40px); height: 100%"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info-extra">
            <div class="info-extra-head">
              <el-row>
                <el-col :span="12">
                  <h2>信息抽取</h2>
                </el-col>
                <el-col :span="12" style="text-align: right">
                  <el-button
                    type="primary"
                    style="margin: 17px 0"
                    @click="metaBtnClick"
                    >查看 Meta 分析结果</el-button
                  >
                </el-col>
              </el-row>
            </div>
            <div class="info-extra-table" :style="{ height: imageHeight }">
              <el-table
                :data="pubTableData"
                border
                stripe
                style="width: 100%"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              >
                <el-table-column prop="title" label="出版信息" />
                <el-table-column prop="detail" label="" width="500" />
                <!-- <el-table-column prop="opration" label="操作" /> -->
              </el-table>
              <el-table
                :data="commonTableData"
                border
                stripe
                style="width: 100%"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              >
                <el-table-column prop="title" label="一般信息" />
                <el-table-column prop="detail" label="" width="500" />
                <!-- <el-table-column prop="opration" label="操作" /> -->
              </el-table>
              <template v-for="(group_item, index) in groupsData">
                <el-table
                  :key="index"
                  :data="group_item.infos"
                  border
                  stripe
                  style="width: 100%"
                  :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                  }"
                >
                  <el-table-column prop="title" :label="group_item.name">
                  </el-table-column>
                  <el-table-column prop="detail" label="" width="500" />
                </el-table>
              </template>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { getCookie } from "../assets/js/cookie.js";

export default {
  data() {
    return {
      file_id: this.$route.query.file_id,
      file_title: "",
      imageNum: 0,
      imageHeight: "300px",
      currentImagePage: 0,
      pubTableData: [],
      commonTableData: [],
      groupsData: [],
      imageData: [],
      pubNameMap: {
        title: "标题",
        Book_num: "书籍编号",
        CH_num: "刊号",
        abstract: "摘要",
        authors: "作者",
        hospital_source: "单位",
        file_id: "文件编号",
        pub_year: "年份",
        ref_id: "文献索引号",
        address: "地址",
      },
      commonNameMap: {
        acu_form: "中医干预类型",
        acu_medicine: "中药",
        acu_point: "针灸穴位",
        blindness: "盲法",
        complaint: "病人主诉",
        disease: "病种",
        exclusion_std: "排除标准",
        fund: "基金",
        // groups: "",
        include_std: "纳入标准",
        keywords_ch: "中文关键词",
        keywords_en: "英文关键词",
        patient_source: "病人来源",
        rob_allocation: "rob 分配",
        rob_random: "rob 随机方法",
        setting: "类别",
        tcm_disease: "中医病名",
        wm_disease: "西医病名",
      },
      groupNameMap: {
        acu_points: "针灸穴位",
        age: "年龄",
        course: "病程",
        dosage_form: "药物剂型",
        dosage_freq: "药物频次",
        dosage_names: "药物名称",
        efficacy: "有效率",
        mean_age: "平均年龄",
        mean_course: "平均病程",
        name: "分组名称",
        num_total: "分组数量",
        prescription: "方剂名称",
        treat_freq: "干预频次",
        treat_period: "疗程",
        treat_period_num: "疗程数",
        treatmeat: "干预措施",
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    this.initImageHeight();
    this.getData();
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    getData() {
      const that = this;
      let token = getCookie("token");
      if (token == "") {
        alert("还未登录");
        return;
      }
      var file_id = this.file_id;
      axios({
        method: "get",
        url: "http://81.70.100.28:5000/api/v1.0/metafile/" + file_id + "/data",
        headers: {
          token: token,
        },
      }).then(function (response) {
        if (response.data.errno == "0") {
          console.log(response.data.data);
          var data = response.data.data;

          if (data.title != null) {
            that.file_title = data.title;
            that.pubTableData.push({
              title: "标题",
              detail: data.title,
            });
          }
          if (data.authors != null) {
            that.pubTableData.push({
              title: "作者",
              detail: data.authors,
            });
          }
          if (data.hospital_source != null) {
            that.pubTableData.push({
              title: "单位",
              detail: data.hospital_source,
            });
          }
          if (data.pub_year != null) {
            that.pubTableData.push({
              title: "年份",
              detail: data.pub_year,
            });
          }
          if (data.ref_id != null) {
            that.pubTableData.push({
              title: "文献索引号",
              detail: data.ref_id,
            });
          }
          if (data.abstract != null) {
            that.pubTableData.push({
              title: "摘要",
              detail: data.abstract,
            });
          }

          for (const key in data) {
            var detail = data[key];
            if (detail != null && key in that.commonNameMap) {
              that.commonTableData.push({
                title: that.commonNameMap[key],
                detail: detail,
              });
            }
          }
          for (const group_i of data.groups) {
            var group_item = {};
            group_item["name"] = group_i.name;
            var group_item_infos = [];
            for (const key in group_i) {
              var item_detail = group_i[key];
              if (
                item_detail != null &&
                item_detail != "" &&
                key in that.groupNameMap
              ) {
                group_item_infos.push({
                  title: that.groupNameMap[key],
                  detail: item_detail,
                });
              }
            }
            group_item["infos"] = group_item_infos;
            that.groupsData.push(group_item);
          }

          console.log("image_num = " + data.image_num);
          that.imageNum = data.image_num;
          that.imageData.splice(0, that.imageData.length);
          for (let index = 0; index < that.imageNum; index++) {
            that.imageData.push("empty");
          }

          if (that.imageNum > 0) {
            that.getImgsData(that.currentImagePage);
          }
        } else {
          alert("请求失败！");
        }
      });
    },

    /**
     * 网络图像文件转Base64
     */
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      var dataURL = canvas.toDataURL("image/" + ext);
      return dataURL;
    },
    getImgsData(index) {
      const that = this;

      if (that.imageData[index] != "empty") {
        return;
      }
      console.log("请求图像");

      let token = getCookie("token");
      if (token == "") {
        alert("还未登录");
        return;
      }
      var file_id = this.file_id;
      var url =
        "http://81.70.100.28:5000/api/v1.0/metafile/" +
        file_id +
        "/image/" +
        index;
      console.log("url = " + url);
      axios({
        method: "get",
        url: url,
        headers: {
          token: token,
        },
      }).then(function (response) {
        let img = "data:image/png;base64," + response.data.data;
        that.imageData.splice(index, 1, img);
      });
    },
    handleCurrentChange: function (val) {
      this.currentImagePage = val;
      this.getImgsData(this.currentImagePage);
    },
    initImageHeight: function () {
      var imageWidth = (document.documentElement.clientWidth * 0.92) / 2 - 80;
      this.imageHeight = (imageWidth * 1276) / 942 + 64 + "px";
    },
    metaBtnClick() {
      var query = {};
      query["keywords"] = this.file_title;
      query["file_id"] = this.file_id;
      this.$router.push({
        path: "../../metafile",
        query: query,
      });
    },
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.read-container {
  width: 92%;
  margin-left: 4%;
}
.origin-doc {
  margin: 20px;
}
.info-extra {
  margin: 20px;
}

.info-extra-table {
  overflow: auto;
}
h2 {
  text-align: left;
}
</style>
