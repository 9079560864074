import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import ElementUI from "element-ui"
import "../src/assets/css/theme/index.css"
Vue.use(ElementUI)

import uploader from "vue-simple-uploader"
Vue.use(uploader)

import axios from "axios"
axios.defaults.withCredentials = true
axios.defaults.timeout = 50000
/**
 * axios拦截器
 */
axios.interceptors.request.use(
  function (config) {
    console.log("请求参数：", config)
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  function (response) {
    console.log("返回结果：", response)
    return response
  },
  (error) => {
    console.log("返回错误：", error)
    return Promise.reject(error)
  }
)

Vue.prototype.$axios = axios

import navBar from "./components/NavBar.vue"
Vue.component("navBar", navBar)

Vue.config.productionTip = false

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
})
