import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

import home from "../components/HomePage.vue"
import upload from "../components/Upload.vue"
import myfile from "../components/MyFile.vue"
import docDetail from "../components/DocDetail.vue"
import search from "../components/SearchPage.vue"
import searchResult from "../components/SearchResultPage.vue"
import metamap from "../components/MetaMap.vue"
import metafile from "../components/MetaFile.vue"
import docs from "../components/DocsPage.vue"
import login from "../components/account/Login.vue"
import register from "../components/account/Register.vue"
import modify from "../components/account/Modify.vue"

// 解决重复点击路由报错的BUG
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: "/",
    name: "首页",
    component: home,
  },
  {
    path: "/home",
    name: "主页",
    component: home,
  },
  {
    path: "/upload",
    name: "上传文献",
    component: upload,
  },
  {
    path: "/docs/detail",
    name: "阅读文献",
    component: docDetail,
  },
  {
    path: "/search",
    name: "文献数据库",
    component: search,
  },
  {
    path: "/search/result",
    name: "搜索结果",
    component: searchResult,
  },
  {
    path: "/docs",
    name: "全部文献",
    component: docs,
  },
  {
    path: "/myfile",
    name: "我的文献",
    component: myfile,
  },
  {
    path: "/metamap",
    name: "Meta 图谱",
    component: metamap,
  },
  {
    path: "/metafile",
    name: "Meta 分析",
    component: metafile,
  },
  {
    path: "/account/login",
    name: "登录",
    component: login,
  },
  {
    path: "/account/register",
    name: "注册",
    component: register,
  },
  {
    path: "/account/modify",
    name: "修改密码",
    component: modify,
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
