<!-- 这是首页 -->
<template>
  <div class="home">
    <navBar></navBar>
    <div class="detail">
      <h1 class="banner-title1">中医药文献信息自动抽取分析系统</h1>
      <div class="feature-intro">
        <div class="feature-intro-left">
          <p>
            本平台可以快速地将医学工作者所需要了解某种疾病的大量相关文献的实验结论以及实验数据进行提取提供医学佐证，协助医学工作者根据实验结论及实验数据快速分析出哪种医疗手段在什么样的情况下更能实现有效的治疗。文献数据库中已收录超过
            6
            万篇中医药优势病种文献，通过大数据的方法，根据结构化信息，从海量文献中自动检索、查找具有同质性的研究，针对合并效应值进行假设检验，以检验多个同类研究的合并效应值是否具有统计学意义。
          </p>
          <el-row>
            <el-button type="primary" @click="uploadClick">上传文献</el-button>
            <el-button type="primary" @click="docsClick">文献数据库</el-button>
            <el-button type="text" @click="docsClick"
              >医学文献数据共享及使用协议</el-button
            >
            <el-button type="text" @click="docsClick">联系我们</el-button>
          </el-row>
        </div>
        <div class="feature-intro-right">
          <div class="home-banner"></div>
        </div>
      </div>
      <div class="feature-content">
        <div class="feature-item">
          <div class="bg-icon1"></div>
          <h3>OCR 自动提取文本信息</h3>
          <p>
            基于 OCR
            技术，结合识别后的文本以及文本的位置坐标信息，采用卷积神经网络的文本分类方法，通过大量的样本标注和训练，实现对文献材料的内容识别。
          </p>
        </div>
        <div class="feature-item">
          <div class="bg-icon2"></div>
          <h3>文献信息抽取</h3>
          <p>
            自动处理大量医学文献，从原本较长的非结构化数据中抽取病人症状、年龄、病种、处方、干预措施、药物、研究方法和结论等重要的临床信息形成结构化数据库。将中医研究人员从繁重的数据提取工作中解放出来，使他们能将有限的精力集中在更有价值的、更需要中医智慧推进的探索工作中去。
          </p>
        </div>
        <div class="feature-item">
          <div class="bg-icon3"></div>
          <h3>Meta 自动分析</h3>
          <p>
            利用结构化数据中的实体例如病种、方剂、药物、穴位以及对应RCT实验的数据，构建中医药领域知识图谱，以“一图胜千言”的形式展现海量中医药文献的研究成果。同时对于海量文档分类索引，并实现对综合情况下的相似文献关联对比分析，完成医学信息跨时间与空间的数据共享。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  methods: {
    uploadClick () {
      this.$router.push({ path: "/upload" })
    },
    docsClick () {
      this.$router.push({ path: "/docs" })
    },
  },
  data () {
    return {}
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
};
</script>

<style scoped>
/* @import url(); 引入css类 */
.detail {
  /* margin: 20px; */
  background-color: #fff;
}

.feature-intro {
  overflow: hidden;
  max-width: 1300px;
  width: 90%;
  margin: 40px auto 0;
  line-height: 150%;
  display: flex;
  align-items: center;
}

.feature-intro-left {
  text-align: left;
  margin: 40px auto;
  vertical-align: top;
  float: left;
  width: 66.66%;
  padding: 0 10px;
  box-sizing: border-box;
}

.feature-intro-right {
  text-align: center;
  max-width: 500px;
  margin: 40px auto;
  vertical-align: top;
  float: left;
  width: 33.33%;
  padding: 0 10px;
  box-sizing: border-box;
}
.feature-intro-right .home-banner {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  background: url(../assets/images/home-banner.png);
}

.feature-intro p {
  font-size: 16px;
  line-height: 175%;
}

.feature-content {
  margin: 80px auto;
  overflow: hidden;
  max-width: 1300px;
  width: 92%;
}

.feature-item {
  vertical-align: top;
  float: left;
  width: 33.33%;
  padding: 0 10px;
  box-sizing: border-box;
}

.feature-item h3 {
  font-size: 24px;
  line-height: 150%;
  margin: 38px 0 4px;
  font-weight: 600;
  letter-spacing: 2px;
}

.feature-item p {
  max-width: 310px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 175%;
  color: #666666;
}
.bg-icon1 {
  width: 88px;
  height: 88px;
  margin: 0 auto;
  background: url(../assets/images/home-ocr.png);
}
.bg-icon2 {
  width: 88px;
  height: 88px;
  margin: 0 auto;
  background: url(../assets/images/home-extract.png);
}
.bg-icon3 {
  width: 88px;
  height: 88px;
  margin: 0 auto;
  background: url(../assets/images/home-map.png);
}
.banner-title1 {
  padding-top: 5%;
  font-size: 40px;
  color: #333;
}
</style>
