<!-- 上传文献页面 -->
<template>
  <div class="upload">
    <navBar></navBar>
    <div class="upload-container">
      <div
        class="steps"
        style="text-align: center; max-width: 800px; margin: 0 auto"
      >
        <el-steps :active="this.currentStep" align-center>
          <el-step title="上传文献"></el-step>
          <el-step title="上传成功"></el-step>
          <el-step title="查看进度"></el-step>
        </el-steps>
      </div>
      <template v-if="this.currentStep == 1">
        <div class="steps-container">
          <uploader
            :options="options"
            class="uploader-example"
            @file-added="onFileAdded"
          >
            <uploader-unsupport></uploader-unsupport>
            <template v-if="isShowDrop">
              <uploader-drop>
                <div class="uploader-drop-sub">
                  <p>拖入需要处理的文件</p>
                  <p>仅支持 PDF 格式，最多同时处理 6 篇</p>
                  <uploader-btn :attrs="attrs">选择文件</uploader-btn>
                </div>
              </uploader-drop>
            </template>
            <template v-else>
              <uploader-list></uploader-list>
              <uploader-btn :attrs="attrs">添加文件</uploader-btn>
              <el-button
                style="margin: 10px 10px"
                type="primary"
                @click="jumpSuccess"
              >
                上传完成
              </el-button>
            </template>
          </uploader>
        </div>
      </template>
      <template v-else-if="this.currentStep == 2">
        <div class="steps-container">
          <div class="upload-succeed">
            <div class="upload-succeed-sub">
              <h3>上传成功</h3>
              <p>页数较多的文献处理时间较长，请耐心等待。</p>
              <el-button
                style="margin: 10px 0"
                size="small"
                type="primary"
                @click="jumpProcess"
                >查看进度</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="this.currentStep == 3">
        <div class="steps-container">
          <div class="upload-process">
            <el-table :data="tableData" border stripe style="width: 100%">
              <el-table-column prop="file_id" label="编号" width="100" />
              <el-table-column prop="file_name" label="文献名称" width="400">
                <template slot-scope="scope">
                  <a
                    @click="jump_detail(scope.$index, scope.row)"
                    style="color: blue; cursor: pointer"
                    >{{ scope.row.file_name }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column prop="percentage" label="处理进度">
                <template slot-scope="scope">
                  <el-progress
                    :text-inside="true"
                    :stroke-width="26"
                    :percentage="scope.row.percentage"
                  ></el-progress>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleView(scope.$index, scope.row)"
                    >查 看</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删 除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getCookie } from "../assets/js/cookie.js"
import axios from "axios"
export default {
  data () {
    return {
      tableData: [],
      isShowDrop: true,
      currentStep: 1,
      options: {
        target: "http://81.70.100.28:5000/api/v1.0/upload",
        testChunks: false,
        autoStart: false,
        simultaneousUploads: 3, //并发上传数
        headers: {
          token: getCookie("token"),
        },
      },
      attrs: {
        accept: ".pdf",
      },
    }
  },
  methods: {
    onFileAdded () {
      console.log("添加文件")
      this.isShowDrop = false
    },
    jumpSuccess () {
      this.currentStep += 1
    },
    jumpProcess () {
      this.currentStep += 1
      this.getFileData()
    },
    getFileData () {
      const that = this
      let token = getCookie("token")
      if (token == "") {
        alert("还未登录")
        return
      }
      axios({
        method: "get",
        url: "http://81.70.100.28:5000/api/v1.0/user/processes",
        headers: {
          token: token,
        },
      }).then(function (response) {
        if (response.data.errno == "0") {
          console.log(response.data.data)
          that.tableData = response.data.data
        } else {
          alert("请求失败！")
        }
      })
    },
    handleView (index, row) {
      console.log(row["file_id"])
      this.$router.push({
        path: "/docs/detail",
        query: {
          file_id: row["file_id"],
        },
      })
    },
    handleDelete (index, row) {
      console.log(index, row)
      const that = this
      let token = getCookie("token")
      if (token == "") {
        alert("还未登录")
        return
      }
      axios({
        method: "put",
        url:
          "http://81.70.100.28:5000/api/v1.0/process/" +
          row["process_id"] +
          "/delete",
        headers: {
          token: token,
        },
      }).then(function (response) {
        if (response.data.errno == "0") {
          console.log(response.data.data)
          that.tableData.splice(index, 1)
        } else {
          alert("删除失败！")
        }
      })
    },
    jump_detail (index, row) {
      console.log(row["file_id"])
      this.$router.push({
        path: "docs/detail",
        query: {
          file_id: row["file_id"],
        },
      })
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.upload-container {
  overflow: hidden;
  max-width: 1300px;
  width: 80%;
  margin: 40px auto 0;
  line-height: 150%;
  text-align: center;
}
.uploader-example {
  width: 100%;
  height: 400px;
  font-size: 12px;
}
.uploader-example .uploader-btn {
  margin: 19px 20px 20px 20px;
  background-color: #42b983;
  border-color: #42b983;
  color: #fff;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1 px solid #42b983;
  border-color: #42b983;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}
.uploader-file-name {
  text-align: left;
}
.el-button {
  margin: 20px;
  background-color: #42b983;
  border-color: #42b983;
  color: #fff;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1 px solid #42b983;
  border-color: #42b983;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.uploader-example .uploader-drop {
  height: 400px;
  width: 100%;
  border: 0px;
  border-radius: 6px;
  padding: 0;
  margin: 0;
}
.uploader-example .uploader-drop .uploader-drop-sub {
  height: 100px;
  font-size: 16px;
  margin-top: 150px;
}
.steps-container {
  margin: 40px auto 0;
  height: auto;
  border: 2px dashed #e0dddd;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
}
.upload-succeed {
  height: 400px;
}
.upload-succeed-sub {
  margin-top: 150px;
}
.avatar {
  width: 100px;
  height: 100px;
}
</style>
