<!-- 搜索结果页 -->
<template>
  <div class="search-result">
    <navBar></navBar>
    <div class="search-result-container">
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column prop="file_id" label="编号" width="200" />
        <el-table-column prop="title" label="文献名称" width="700">
          <template slot-scope="scope">
            <a
              @click="jump_detail(scope.$index, scope.row)"
              style="color: blue; cursor: pointer"
              >{{ scope.row.title }}</a
            >
          </template>
        </el-table-column>
        <el-table-column prop="status" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleView(scope.$index, scope.row)"
              >查 看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div class="page-container">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="20"
        :pager-count="11"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import axios from "axios"
import { getCookie } from "../assets/js/cookie.js"

export default {
  data () {
    return {
      keywords: this.$route.query.keywords,
      disease: this.$route.query.disease,
      pub_year: this.$route.query.pub_year,
      tableData: [],
      currentPage: 1,
    }
  },
  methods: {
    getData (index) {
      const that = this
      let token = getCookie("token")
      if (token == "") {
        alert("还未登录")
        return
      }

      if (this.keywords == undefined) {
        this.keywords = ""
      }
      if (this.pub_year == undefined) {
        this.pub_year = ""
      }
      if (this.disease == undefined) {
        this.disease = ""
      }

      let data = {
        keywords: this.keywords,
        disease: this.disease,
        pub_year: this.pub_year,
        page_index: index,
        page_size: 20,
      }

      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key]
          console.log(key, element)
        }
      }
      axios({
        method: "post",
        url: "http://81.70.100.28:5000/api/v1.0/metafile/query",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        data: data,
      }).then(function (response) {
        console.log(response.data)
        that.tableData = response.data.data
        if (response.data.errno == "0") {
          console.log(response.data.data)
        } else {
          alert("请求失败！")
        }
      })
    },
    handleCurrentChange: function (val) {
      this.currentPage = val
      this.getData(this.currentPage)
    },
    handleView (index, row) {
      console.log(row["file_id"])
      this.$router.push({
        path: "../docs/detail",
        query: {
          file_id: row["file_id"],
        },
      })
    },
    jump_detail (index, row) {
      console.log(row["file_id"])
      this.$router.push({
        path: "../docs/detail",
        query: {
          file_id: row["file_id"],
        },
      })
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this.getData(this.currentPage)
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.search-result {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.search-result-container {
  margin: 20px auto;
  width: 80%;
  text-align: center;
  overflow: auto;
}
.el-button {
  background-color: #42b983;
  border-color: #42b983;
}
</style>
