<!--  -->
<template>
  <div class="metamap" clearfix>
    <navBar></navBar>
    <div id="echarts">
      <div id="main-echarts"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"

export default {
  data () {
    return {}
  },
  methods: {
    initECharts: function () {
      var mySize = document.getElementById("echarts")
      // 给宽度 -270 是取合适自己盒子的值
      mySize.style.width = window.innerWidth - 40 + "px"
      // 给高度 -650 是取合适自己盒子的值
      mySize.style.height = window.innerHeight - 101 + "px"

      let myChart = echarts.init(document.getElementById("main-echarts"))
      myChart.setOption(this.setOption())
      // 跟着缩放
      window.addEventListener("resize", function () {
        myChart.resize() //自适应大小
      })
    },
    setOption: function () {
      let option = {
        backgroundColor: "rgba(255,250,205,0.2)",
        animation: true,
        animationThreshold: 2000,
        animationDuration: 1000,
        animationEasing: "cubicOut",
        animationDelay: 0,
        animationDurationUpdate: 300,
        animationEasingUpdate: "cubicOut",
        animationDelayUpdate: 0,
        color: [
          "#c23531",
          "#2f4554",
          "#61a0a8",
          "#d48265",
          "#749f83",
          "#ca8622",
          "#bda29a",
          "#6e7074",
          "#546570",
          "#c4ccd3",
          "#f05b72",
          "#ef5b9c",
          "#f47920",
          "#905a3d",
          "#fab27b",
          "#2a5caa",
          "#444693",
          "#726930",
          "#b2d235",
          "#6d8346",
          "#ac6767",
          "#1d953f",
          "#6950a1",
          "#918597",
        ],
        series: [
          {
            type: "graph",
            layout: "force",
            symbolSize: 10,
            circular: {
              rotateLabel: false,
            },
            force: {
              repulsion: 500,
              edgeLength: 200,
              gravity: 0.2,
            },
            label: {
              show: true,
              position: "top",
              margin: 8,
            },
            lineStyle: {
              show: true,
              width: 1,
              opacity: 1,
              curveness: 0.2,
              type: "solid",
            },
            roam: true,
            draggable: true,
            focusNodeAdjacency: true,
            data: [
              {
                name: "\u819d\u9aa8\u5173\u8282\u708e",
                symbolSize: 87,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 7642,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                symbolSize: 71,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 5135,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                symbolSize: 132,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 17616,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7cd6\u5c3f\u75c5\u80be\u75c5",
                symbolSize: 64,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 4210,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7cd6\u5c3f\u75c5",
                symbolSize: 61,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 3731,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8111\u6897\u6b7b",
                symbolSize: 120,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 14412,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80a5\u80d6",
                symbolSize: 50,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 2543,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ed3\u76f4\u80a0\u764c",
                symbolSize: 34,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 1214,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                symbolSize: 59,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 3562,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "COPD",
                symbolSize: 7,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 60,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ad8\u8840\u538b",
                symbolSize: 10,
                is_fixed: true,
                gravity: 1,
                draggable: "False",
                value: 105,
                category: "diseases",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81ea\u62df\u4e2d\u836f\u65b9",
                symbolSize: 14,
                draggable: "True",
                value: 88,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u590d\u65b9\u4e39\u53c2",
                symbolSize: 63,
                draggable: "True",
                value: 1007,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8865\u9633\u8fd8\u4e94\u6c64",
                symbolSize: 61,
                draggable: "True",
                value: 957,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                symbolSize: 55,
                draggable: "True",
                value: 809,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u52a0\u5473\u900d\u9065\u6563",
                symbolSize: 9,
                draggable: "True",
                value: 40,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u516d\u5473\u5730\u9ec4\u4e38",
                symbolSize: 31,
                draggable: "True",
                value: 318,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82d3\u6842\u672f\u7518\u6c64",
                symbolSize: 14,
                draggable: "True",
                value: 91,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u517b\u9634\u6d3b\u8840\u6c64",
                symbolSize: 13,
                draggable: "True",
                value: 78,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u963f\u6258\u4f10\u4ed6\u6c40",
                symbolSize: 24,
                draggable: "True",
                value: 201,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u517b\u9634\u6d3b\u8840\u65b9",
                symbolSize: 10,
                draggable: "True",
                value: 51,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6842\u679d\u832f\u82d3\u4e38",
                symbolSize: 13,
                draggable: "True",
                value: 78,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u795b\u75f0\u5316\u7600\u6c64",
                symbolSize: 9,
                draggable: "True",
                value: 44,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u964d\u7cd6\u80f6\u56ca",
                symbolSize: 25,
                draggable: "True",
                value: 220,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77e5\u67cf\u5730\u9ec4\u6c64",
                symbolSize: 9,
                draggable: "True",
                value: 40,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u683c\u534e\u6b62",
                symbolSize: 6,
                draggable: "True",
                value: 20,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8840\u8102\u5eb7\u80f6\u56ca",
                symbolSize: 17,
                draggable: "True",
                value: 114,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8840\u5e9c\u9010\u7600\u6c64",
                symbolSize: 29,
                draggable: "True",
                value: 279,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u836f\u590d\u65b9",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e39\u53c2\u6ce8\u5c04\u6db2",
                symbolSize: 23,
                draggable: "True",
                value: 194,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u901a\u5fc3\u7edc",
                symbolSize: 15,
                draggable: "True",
                value: 97,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u94f6\u674f\u53f6\u7247",
                symbolSize: 13,
                draggable: "True",
                value: 79,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8033\u7a74\u8d34\u538b",
                symbolSize: 13,
                draggable: "True",
                value: 76,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u845b\u6839\u7d20",
                symbolSize: 14,
                draggable: "True",
                value: 84,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                symbolSize: 26,
                draggable: "True",
                value: 235,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5316\u75f0\u901a\u8109\u996e",
                symbolSize: 6,
                draggable: "True",
                value: 24,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c0f\u9677\u80f8\u6c64",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7389\u6db2\u6c64",
                symbolSize: 6,
                draggable: "True",
                value: 25,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u845b\u6839\u7d20\u6ce8\u5c04\u6db2",
                symbolSize: 16,
                draggable: "True",
                value: 110,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51b3\u660e\u5b50\u8336",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e8c\u9648\u6c64",
                symbolSize: 8,
                draggable: "True",
                value: 34,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u52a0\u5473\u6e29\u80c6\u6c64",
                symbolSize: 6,
                draggable: "True",
                value: 25,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5229\u62c9\u9c81\u80bd",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6843\u7ea2\u56db\u7269\u6c64",
                symbolSize: 22,
                draggable: "True",
                value: 186,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u80be\u5316\u7600\u6c64",
                symbolSize: 12,
                draggable: "True",
                value: 70,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u533b\u98df\u7597",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51cf\u80a5\u8c03\u8102\u80f6\u56ca",
                symbolSize: 6,
                draggable: "True",
                value: 25,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u517b\u9634\u4e2d\u836f",
                symbolSize: 10,
                draggable: "True",
                value: 54,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u517b\u9634\u6c64",
                symbolSize: 9,
                draggable: "True",
                value: 39,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u516d\u5473\u5730\u9ec4\u6c64",
                symbolSize: 9,
                draggable: "True",
                value: 41,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u836f\u81ea\u62df\u65b9",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e09\u4ec1\u6c64",
                symbolSize: 13,
                draggable: "True",
                value: 74,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                symbolSize: 6,
                draggable: "True",
                value: 23,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80f0\u5c9b\u7d20",
                symbolSize: 10,
                draggable: "True",
                value: 48,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u67f4\u80e1\u6c64",
                symbolSize: 16,
                draggable: "True",
                value: 112,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d88\u6e34\u65b9",
                symbolSize: 5,
                draggable: "True",
                value: 15,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u590d\u65b9\u4e39\u53c2\u7247",
                symbolSize: 6,
                draggable: "True",
                value: 25,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82cd\u9644\u5bfc\u75f0\u6c64",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d88\u6e34\u4e38",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u534a\u590f\u767d\u672f\u5929\u9ebb\u6c64",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u6d3b\u8840\u6cd5",
                symbolSize: 18,
                draggable: "True",
                value: 127,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u53c2\u82aa\u964d\u7cd6\u9897\u7c92",
                symbolSize: 7,
                draggable: "True",
                value: 26,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8840\u8102\u5eb7",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u53c2\u82d3\u767d\u672f\u6563",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ec4\u82aa\u6ce8\u5c04\u6db2",
                symbolSize: 7,
                draggable: "True",
                value: 32,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e03\u5473\u767d\u672f\u6563",
                symbolSize: 10,
                draggable: "True",
                value: 49,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d25\u529b\u8fbe\u9897\u7c92",
                symbolSize: 7,
                draggable: "True",
                value: 30,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u517b\u9634\u901a\u8109\u65b9",
                symbolSize: 7,
                draggable: "True",
                value: 26,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d88\u6e34\u6c64",
                symbolSize: 5,
                draggable: "True",
                value: 18,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e94\u82d3\u6563",
                symbolSize: 7,
                draggable: "True",
                value: 31,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e8c\u7532\u53cc\u80cd",
                symbolSize: 9,
                draggable: "True",
                value: 42,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ec4\u8fde\u6e29\u80c6\u6c64",
                symbolSize: 8,
                draggable: "True",
                value: 38,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6c14\u517b\u9634\u65b9",
                symbolSize: 7,
                draggable: "True",
                value: 32,
                category: "prescriptions",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u963f\u9b4f",
                symbolSize: 5,
                draggable: "True",
                value: 73,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5434\u8331\u8438",
                symbolSize: 11,
                draggable: "True",
                value: 448,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e39\u53c2",
                symbolSize: 42,
                draggable: "True",
                value: 12006,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ea2\u53c2",
                symbolSize: 14,
                draggable: "True",
                value: 767,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9644\u5b50",
                symbolSize: 27,
                draggable: "True",
                value: 3873,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u828e",
                symbolSize: 26,
                draggable: "True",
                value: 3591,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ec4\u82aa",
                symbolSize: 29,
                draggable: "True",
                value: 4916,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5168\u874e",
                symbolSize: 17,
                draggable: "True",
                value: 1197,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c34\u86ed",
                symbolSize: 24,
                draggable: "True",
                value: 3105,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e73\u9999",
                symbolSize: 16,
                draggable: "True",
                value: 1145,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u83aa\u672f",
                symbolSize: 14,
                draggable: "True",
                value: 748,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8349\u4e4c",
                symbolSize: 14,
                draggable: "True",
                value: 765,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6842\u679d",
                symbolSize: 32,
                draggable: "True",
                value: 5998,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u571f\u9cd6\u866b",
                symbolSize: 12,
                draggable: "True",
                value: 542,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u9ec4",
                symbolSize: 27,
                draggable: "True",
                value: 4041,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u725b\u819d",
                symbolSize: 29,
                draggable: "True",
                value: 4650,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6ca1\u836f",
                symbolSize: 17,
                draggable: "True",
                value: 1298,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e09\u68f1",
                symbolSize: 11,
                draggable: "True",
                value: 450,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u964d\u9999",
                symbolSize: 13,
                draggable: "True",
                value: 732,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u751f\u5730",
                symbolSize: 29,
                draggable: "True",
                value: 4555,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u832f\u82d3",
                symbolSize: 38,
                draggable: "True",
                value: 9230,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6843\u4ec1",
                symbolSize: 32,
                draggable: "True",
                value: 6242,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u515a\u53c2",
                symbolSize: 30,
                draggable: "True",
                value: 5191,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8d64\u828d",
                symbolSize: 33,
                draggable: "True",
                value: 6385,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5f53\u5f52",
                symbolSize: 38,
                draggable: "True",
                value: 9315,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ea2\u82b1",
                symbolSize: 36,
                draggable: "True",
                value: 7981,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u751f\u5730\u9ec4",
                symbolSize: 22,
                draggable: "True",
                value: 2331,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u845b\u6839",
                symbolSize: 23,
                draggable: "True",
                value: 2645,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u67f4\u80e1",
                symbolSize: 23,
                draggable: "True",
                value: 2599,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5730\u9ec4",
                symbolSize: 30,
                draggable: "True",
                value: 5231,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7518\u8349",
                symbolSize: 39,
                draggable: "True",
                value: 9877,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u67b3\u58f3",
                symbolSize: 20,
                draggable: "True",
                value: 2001,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767e\u90e8",
                symbolSize: 8,
                draggable: "True",
                value: 199,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e94\u5473\u5b50",
                symbolSize: 23,
                draggable: "True",
                value: 2544,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82cf\u5b50",
                symbolSize: 12,
                draggable: "True",
                value: 538,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6a80\u9999",
                symbolSize: 13,
                draggable: "True",
                value: 613,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7802\u4ec1",
                symbolSize: 14,
                draggable: "True",
                value: 782,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u534a\u590f",
                symbolSize: 31,
                draggable: "True",
                value: 5715,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7d2b\u82cf",
                symbolSize: 11,
                draggable: "True",
                value: 433,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ea6\u51ac",
                symbolSize: 25,
                draggable: "True",
                value: 3256,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6dee\u5c71\u836f",
                symbolSize: 9,
                draggable: "True",
                value: 308,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u675e\u5b50",
                symbolSize: 14,
                draggable: "True",
                value: 801,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82cd\u672f",
                symbolSize: 23,
                draggable: "True",
                value: 2653,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u719f\u5730",
                symbolSize: 23,
                draggable: "True",
                value: 2645,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u83df\u4e1d\u5b50",
                symbolSize: 14,
                draggable: "True",
                value: 807,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u836f",
                symbolSize: 24,
                draggable: "True",
                value: 3091,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u67b8\u675e",
                symbolSize: 15,
                draggable: "True",
                value: 1020,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8865\u9aa8\u8102",
                symbolSize: 15,
                draggable: "True",
                value: 975,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u592a\u5b50\u53c2",
                symbolSize: 18,
                draggable: "True",
                value: 1571,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u672f",
                symbolSize: 32,
                draggable: "True",
                value: 5846,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9648\u76ae",
                symbolSize: 26,
                draggable: "True",
                value: 3588,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5730\u9f99",
                symbolSize: 27,
                draggable: "True",
                value: 3818,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7f8c\u6d3b",
                symbolSize: 16,
                draggable: "True",
                value: 1183,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6728\u74dc",
                symbolSize: 16,
                draggable: "True",
                value: 1044,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4eba\u53c2",
                symbolSize: 22,
                draggable: "True",
                value: 2491,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e21\u8840\u85e4",
                symbolSize: 22,
                draggable: "True",
                value: 2378,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8fdc\u5fd7",
                symbolSize: 16,
                draggable: "True",
                value: 1107,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9632\u98ce",
                symbolSize: 19,
                draggable: "True",
                value: 1733,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5f53\u5f52\u5c3e",
                symbolSize: 11,
                draggable: "True",
                value: 448,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u59dc\u9ec4",
                symbolSize: 11,
                draggable: "True",
                value: 422,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5a01\u7075\u4ed9",
                symbolSize: 18,
                draggable: "True",
                value: 1472,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u83d6\u84b2",
                symbolSize: 19,
                draggable: "True",
                value: 1724,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u72ec\u6d3b",
                symbolSize: 22,
                draggable: "True",
                value: 2470,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6851\u679d",
                symbolSize: 11,
                draggable: "True",
                value: 469,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u83d6\u84b2",
                symbolSize: 20,
                draggable: "True",
                value: 1976,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82cf\u6728",
                symbolSize: 10,
                draggable: "True",
                value: 319,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5f52\u5c3e",
                symbolSize: 12,
                draggable: "True",
                value: 595,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u738b\u4e0d\u7559\u884c",
                symbolSize: 12,
                draggable: "True",
                value: 609,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u65ad",
                symbolSize: 10,
                draggable: "True",
                value: 342,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51b3\u660e",
                symbolSize: 15,
                draggable: "True",
                value: 998,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u94a9\u85e4",
                symbolSize: 16,
                draggable: "True",
                value: 1108,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u675c\u4ef2",
                symbolSize: 19,
                draggable: "True",
                value: 1628,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51b3\u660e\u5b50",
                symbolSize: 11,
                draggable: "True",
                value: 452,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u95e8\u51ac",
                symbolSize: 4,
                draggable: "True",
                value: 40,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e39\u76ae",
                symbolSize: 18,
                draggable: "True",
                value: 1529,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7261\u4e39\u76ae",
                symbolSize: 13,
                draggable: "True",
                value: 655,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82e1\u4ec1",
                symbolSize: 13,
                draggable: "True",
                value: 717,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u6942",
                symbolSize: 18,
                draggable: "True",
                value: 1464,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8584\u8377",
                symbolSize: 9,
                draggable: "True",
                value: 300,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ec4\u8fde",
                symbolSize: 18,
                draggable: "True",
                value: 1554,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77e5\u6bcd",
                symbolSize: 18,
                draggable: "True",
                value: 1564,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u82b1\u7c89",
                symbolSize: 15,
                draggable: "True",
                value: 934,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ea6\u95e8\u51ac",
                symbolSize: 7,
                draggable: "True",
                value: 172,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9632\u5df1",
                symbolSize: 16,
                draggable: "True",
                value: 1100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u85ff\u9999",
                symbolSize: 8,
                draggable: "True",
                value: 210,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u8331\u8438",
                symbolSize: 18,
                draggable: "True",
                value: 1394,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7384\u53c2",
                symbolSize: 16,
                draggable: "True",
                value: 1145,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e09\u4e03",
                symbolSize: 24,
                draggable: "True",
                value: 3110,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e21\u5185\u91d1",
                symbolSize: 9,
                draggable: "True",
                value: 277,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82e6\u53c2",
                symbolSize: 10,
                draggable: "True",
                value: 321,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6728\u9999",
                symbolSize: 14,
                draggable: "True",
                value: 734,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82c1\u84c9",
                symbolSize: 8,
                draggable: "True",
                value: 199,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9996\u4e4c",
                symbolSize: 14,
                draggable: "True",
                value: 839,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ec4\u7cbe",
                symbolSize: 15,
                draggable: "True",
                value: 975,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u82b1\u86c7",
                symbolSize: 13,
                draggable: "True",
                value: 650,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u751f\u59dc",
                symbolSize: 21,
                draggable: "True",
                value: 2158,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8089\u82c1\u84c9",
                symbolSize: 8,
                draggable: "True",
                value: 199,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u534a\u679d\u83b2",
                symbolSize: 9,
                draggable: "True",
                value: 294,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4f55\u9996\u4e4c",
                symbolSize: 12,
                draggable: "True",
                value: 569,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6241\u8c46",
                symbolSize: 10,
                draggable: "True",
                value: 337,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u67cf\u5b50\u4ec1",
                symbolSize: 6,
                draggable: "True",
                value: 120,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u9ebb",
                symbolSize: 17,
                draggable: "True",
                value: 1343,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u73cd\u73e0",
                symbolSize: 6,
                draggable: "True",
                value: 109,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u83ca\u82b1",
                symbolSize: 6,
                draggable: "True",
                value: 120,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u828d",
                symbolSize: 26,
                draggable: "True",
                value: 3556,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5408\u6b22",
                symbolSize: 10,
                draggable: "True",
                value: 343,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5357\u661f",
                symbolSize: 19,
                draggable: "True",
                value: 1593,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u67b3\u5b9e",
                symbolSize: 17,
                draggable: "True",
                value: 1343,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9999\u9644",
                symbolSize: 15,
                draggable: "True",
                value: 924,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u90c1\u91d1",
                symbolSize: 21,
                draggable: "True",
                value: 2092,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6a58\u76ae",
                symbolSize: 6,
                draggable: "True",
                value: 94,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u53ca",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e94\u6307\u6bdb\u6843",
                symbolSize: 5,
                draggable: "True",
                value: 80,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u91d1\u6a31\u5b50",
                symbolSize: 7,
                draggable: "True",
                value: 155,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u6bcd\u8349",
                symbolSize: 17,
                draggable: "True",
                value: 1324,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u571f\u832f\u82d3",
                symbolSize: 11,
                draggable: "True",
                value: 441,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4f69\u5170",
                symbolSize: 9,
                draggable: "True",
                value: 244,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8335\u9648",
                symbolSize: 4,
                draggable: "True",
                value: 37,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8f66\u524d\u5b50",
                symbolSize: 14,
                draggable: "True",
                value: 759,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u897f\u6d0b\u53c2",
                symbolSize: 11,
                draggable: "True",
                value: 434,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u78c1\u77f3",
                symbolSize: 7,
                draggable: "True",
                value: 166,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u85a4\u767d",
                symbolSize: 14,
                draggable: "True",
                value: 853,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u6800",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5e72\u59dc",
                symbolSize: 21,
                draggable: "True",
                value: 2222,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u8438\u8089",
                symbolSize: 14,
                draggable: "True",
                value: 770,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e9d\u9999",
                symbolSize: 15,
                draggable: "True",
                value: 991,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8476\u82c8\u5b50",
                symbolSize: 12,
                draggable: "True",
                value: 559,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u524d\u80e1",
                symbolSize: 8,
                draggable: "True",
                value: 227,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7425\u73c0",
                symbolSize: 8,
                draggable: "True",
                value: 219,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u86e4\u58f3",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8fde\u7fd8",
                symbolSize: 12,
                draggable: "True",
                value: 527,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6f0f\u82a6",
                symbolSize: 4,
                draggable: "True",
                value: 34,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ebb\u9ec4",
                symbolSize: 20,
                draggable: "True",
                value: 1934,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7682\u89d2",
                symbolSize: 8,
                draggable: "True",
                value: 208,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e4c\u68a2\u86c7",
                symbolSize: 10,
                draggable: "True",
                value: 333,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7389\u7c73\u987b",
                symbolSize: 7,
                draggable: "True",
                value: 153,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9cd6\u7532",
                symbolSize: 6,
                draggable: "True",
                value: 127,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u84b2\u9ec4",
                symbolSize: 13,
                draggable: "True",
                value: 696,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8089\u6842",
                symbolSize: 20,
                draggable: "True",
                value: 1977,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u659b",
                symbolSize: 10,
                draggable: "True",
                value: 386,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5143\u53c2",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ec6\u8f9b",
                symbolSize: 22,
                draggable: "True",
                value: 2488,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u4e4c",
                symbolSize: 18,
                draggable: "True",
                value: 1472,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u858f\u82e1\u4ec1",
                symbolSize: 10,
                draggable: "True",
                value: 373,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u725b\u819d",
                symbolSize: 14,
                draggable: "True",
                value: 801,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u79e6\u827d",
                symbolSize: 11,
                draggable: "True",
                value: 437,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6cfd\u6cfb",
                symbolSize: 24,
                draggable: "True",
                value: 2914,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u50f5\u8695",
                symbolSize: 12,
                draggable: "True",
                value: 553,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u51b3\u660e",
                symbolSize: 11,
                draggable: "True",
                value: 403,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u94f6\u82b1",
                symbolSize: 11,
                draggable: "True",
                value: 484,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ec4\u67cf",
                symbolSize: 16,
                draggable: "True",
                value: 1086,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7edc\u77f3\u85e4",
                symbolSize: 8,
                draggable: "True",
                value: 241,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u91d1\u94f6\u82b1",
                symbolSize: 11,
                draggable: "True",
                value: 437,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5fcd\u51ac\u85e4",
                symbolSize: 9,
                draggable: "True",
                value: 267,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9aa8\u788e\u8865",
                symbolSize: 11,
                draggable: "True",
                value: 474,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u963f\u80f6",
                symbolSize: 9,
                draggable: "True",
                value: 285,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9974\u7cd6",
                symbolSize: 4,
                draggable: "True",
                value: 40,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u86e4\u86a7",
                symbolSize: 7,
                draggable: "True",
                value: 152,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u8d1d",
                symbolSize: 12,
                draggable: "True",
                value: 534,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8d1d\u6bcd",
                symbolSize: 15,
                draggable: "True",
                value: 878,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82a5\u5b50",
                symbolSize: 18,
                draggable: "True",
                value: 1514,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u82a5\u5b50",
                symbolSize: 17,
                draggable: "True",
                value: 1345,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u571f\u8d1d\u6bcd",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u84b2\u516c\u82f1",
                symbolSize: 10,
                draggable: "True",
                value: 346,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u97e6",
                symbolSize: 5,
                draggable: "True",
                value: 79,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7eed\u65ad",
                symbolSize: 13,
                draggable: "True",
                value: 635,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9501\u9633",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f9f\u677f",
                symbolSize: 7,
                draggable: "True",
                value: 170,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u832f\u82d3\u76ae",
                symbolSize: 5,
                draggable: "True",
                value: 85,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7af9\u53f6",
                symbolSize: 7,
                draggable: "True",
                value: 136,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u571f\u5927\u9ec4",
                symbolSize: 3,
                draggable: "True",
                value: 25,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51b0\u7247",
                symbolSize: 14,
                draggable: "True",
                value: 845,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u59dc\u6c41",
                symbolSize: 12,
                draggable: "True",
                value: 508,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u51ac",
                symbolSize: 8,
                draggable: "True",
                value: 224,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9178\u67a3\u4ec1",
                symbolSize: 16,
                draggable: "True",
                value: 1056,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u732a\u82d3",
                symbolSize: 14,
                draggable: "True",
                value: 796,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f99\u773c\u8089",
                symbolSize: 7,
                draggable: "True",
                value: 147,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f99\u773c",
                symbolSize: 8,
                draggable: "True",
                value: 192,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6854\u6897",
                symbolSize: 19,
                draggable: "True",
                value: 1626,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8702\u871c",
                symbolSize: 11,
                draggable: "True",
                value: 450,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8749\u8715",
                symbolSize: 8,
                draggable: "True",
                value: 185,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u83b2\u5b50",
                symbolSize: 7,
                draggable: "True",
                value: 177,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u67a3",
                symbolSize: 16,
                draggable: "True",
                value: 1030,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u841d\u535c",
                symbolSize: 6,
                draggable: "True",
                value: 104,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82b9\u83dc",
                symbolSize: 4,
                draggable: "True",
                value: 47,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u677e\u97f3",
                symbolSize: 4,
                draggable: "True",
                value: 47,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6787\u6777",
                symbolSize: 8,
                draggable: "True",
                value: 212,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767e\u5408",
                symbolSize: 11,
                draggable: "True",
                value: 418,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u6728\u8033",
                symbolSize: 4,
                draggable: "True",
                value: 47,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6728\u8033",
                symbolSize: 4,
                draggable: "True",
                value: 47,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u674f\u4ec1",
                symbolSize: 17,
                draggable: "True",
                value: 1336,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6845\u5b50",
                symbolSize: 9,
                draggable: "True",
                value: 276,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u818f",
                symbolSize: 14,
                draggable: "True",
                value: 786,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6606\u5e03",
                symbolSize: 6,
                draggable: "True",
                value: 108,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7261\u86ce",
                symbolSize: 13,
                draggable: "True",
                value: 684,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u65f1\u83b2",
                symbolSize: 9,
                draggable: "True",
                value: 275,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u65f1\u83b2\u8349",
                symbolSize: 7,
                draggable: "True",
                value: 135,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5973\u8d1e\u5b50",
                symbolSize: 12,
                draggable: "True",
                value: 531,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6deb\u7f8a\u85ff",
                symbolSize: 9,
                draggable: "True",
                value: 258,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5e7f\u90c1\u91d1",
                symbolSize: 5,
                draggable: "True",
                value: 73,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c0f\u9ea6",
                symbolSize: 6,
                draggable: "True",
                value: 112,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ebb\u4ec1",
                symbolSize: 7,
                draggable: "True",
                value: 180,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u67ff\u8482",
                symbolSize: 6,
                draggable: "True",
                value: 106,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f99\u9aa8",
                symbolSize: 14,
                draggable: "True",
                value: 827,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8def\u8def\u901a",
                symbolSize: 11,
                draggable: "True",
                value: 455,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u50f5\u8695",
                symbolSize: 4,
                draggable: "True",
                value: 42,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ef6\u80e1\u7d22",
                symbolSize: 18,
                draggable: "True",
                value: 1400,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u8d1d\u6bcd",
                symbolSize: 10,
                draggable: "True",
                value: 349,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u679c",
                symbolSize: 5,
                draggable: "True",
                value: 84,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u8d1d\u6bcd",
                symbolSize: 3,
                draggable: "True",
                value: 20,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80e1\u6843",
                symbolSize: 7,
                draggable: "True",
                value: 130,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6b3e\u51ac\u82b1",
                symbolSize: 9,
                draggable: "True",
                value: 290,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7d2b\u82d1",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u87fe\u9165",
                symbolSize: 6,
                draggable: "True",
                value: 106,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u725b\u9ec4",
                symbolSize: 9,
                draggable: "True",
                value: 291,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82cf\u5408\u9999",
                symbolSize: 13,
                draggable: "True",
                value: 673,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e4c\u5934",
                symbolSize: 6,
                draggable: "True",
                value: 127,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ad8\u826f\u59dc",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u826f\u59dc",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5e7f\u85ff\u9999",
                symbolSize: 5,
                draggable: "True",
                value: 60,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u82b7",
                symbolSize: 12,
                draggable: "True",
                value: 530,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u827e\u53f6",
                symbolSize: 12,
                draggable: "True",
                value: 544,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9c7c\u8165\u8349",
                symbolSize: 14,
                draggable: "True",
                value: 863,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5347\u9ebb",
                symbolSize: 8,
                draggable: "True",
                value: 242,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6cd5\u590f",
                symbolSize: 4,
                draggable: "True",
                value: 54,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e94\u52a0\u76ae",
                symbolSize: 8,
                draggable: "True",
                value: 208,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u94f6\u674f",
                symbolSize: 10,
                draggable: "True",
                value: 385,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u523a\u4e94\u52a0",
                symbolSize: 7,
                draggable: "True",
                value: 134,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e4c\u836f",
                symbolSize: 10,
                draggable: "True",
                value: 322,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4ed9\u7075\u813e",
                symbolSize: 9,
                draggable: "True",
                value: 301,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5df4\u621f\u5929",
                symbolSize: 13,
                draggable: "True",
                value: 613,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4ed9\u8305",
                symbolSize: 10,
                draggable: "True",
                value: 330,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f99\u9f7f",
                symbolSize: 3,
                draggable: "True",
                value: 20,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u8349",
                symbolSize: 9,
                draggable: "True",
                value: 293,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u72d7\u810a",
                symbolSize: 11,
                draggable: "True",
                value: 457,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82b1\u6912",
                symbolSize: 8,
                draggable: "True",
                value: 189,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6a58\u7ea2",
                symbolSize: 10,
                draggable: "True",
                value: 351,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u667a",
                symbolSize: 8,
                draggable: "True",
                value: 208,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5730\u9aa8\u76ae",
                symbolSize: 9,
                draggable: "True",
                value: 245,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c04\u5e72",
                symbolSize: 9,
                draggable: "True",
                value: 269,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82a6\u6839",
                symbolSize: 6,
                draggable: "True",
                value: 89,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u867b\u866b",
                symbolSize: 7,
                draggable: "True",
                value: 132,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ea2\u85e4",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u795e\u66f2",
                symbolSize: 9,
                draggable: "True",
                value: 245,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5e7f\u5730\u9f99",
                symbolSize: 2,
                draggable: "True",
                value: 15,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7389\u7af9",
                symbolSize: 8,
                draggable: "True",
                value: 239,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u539a\u6734",
                symbolSize: 16,
                draggable: "True",
                value: 1051,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4f5b\u624b",
                symbolSize: 8,
                draggable: "True",
                value: 215,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e4c\u6885",
                symbolSize: 8,
                draggable: "True",
                value: 209,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6bdb\u51ac\u9752",
                symbolSize: 10,
                draggable: "True",
                value: 326,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80c6\u661f",
                symbolSize: 4,
                draggable: "True",
                value: 51,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u7afa\u9ec4",
                symbolSize: 9,
                draggable: "True",
                value: 271,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u6912",
                symbolSize: 5,
                draggable: "True",
                value: 75,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u76ca\u667a\u4ec1",
                symbolSize: 7,
                draggable: "True",
                value: 178,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5411\u524d",
                symbolSize: 12,
                draggable: "True",
                value: 521,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5bbd\u7b4b\u85e4",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6851\u53f6",
                symbolSize: 4,
                draggable: "True",
                value: 50,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u571f\u725b\u819d",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c34\u725b\u89d2",
                symbolSize: 5,
                draggable: "True",
                value: 87,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9752\u9edb",
                symbolSize: 3,
                draggable: "True",
                value: 23,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u67af\u8349",
                symbolSize: 11,
                draggable: "True",
                value: 408,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u590f\u67af\u8349",
                symbolSize: 11,
                draggable: "True",
                value: 408,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8292\u785d",
                symbolSize: 13,
                draggable: "True",
                value: 621,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6cfd\u5170",
                symbolSize: 15,
                draggable: "True",
                value: 967,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u74e6\u695e\u5b50",
                symbolSize: 3,
                draggable: "True",
                value: 24,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82a1\u5b9e",
                symbolSize: 8,
                draggable: "True",
                value: 210,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8702\u623f",
                symbolSize: 4,
                draggable: "True",
                value: 45,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5143\u80e1",
                symbolSize: 8,
                draggable: "True",
                value: 234,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8695\u7802",
                symbolSize: 4,
                draggable: "True",
                value: 35,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u751f\u77f3\u818f",
                symbolSize: 10,
                draggable: "True",
                value: 389,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9752\u84bf",
                symbolSize: 7,
                draggable: "True",
                value: 161,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d77\u98ce\u85e4",
                symbolSize: 7,
                draggable: "True",
                value: 146,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51ac\u74dc\u4ec1",
                symbolSize: 4,
                draggable: "True",
                value: 40,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e1d\u74dc\u7edc",
                symbolSize: 6,
                draggable: "True",
                value: 109,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8708\u86a3",
                symbolSize: 6,
                draggable: "True",
                value: 97,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5f90\u957f\u537f",
                symbolSize: 8,
                draggable: "True",
                value: 215,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u5357\u661f",
                symbolSize: 6,
                draggable: "True",
                value: 93,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u831c\u8349",
                symbolSize: 7,
                draggable: "True",
                value: 154,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e94\u7075\u8102",
                symbolSize: 11,
                draggable: "True",
                value: 467,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d77\u85fb",
                symbolSize: 6,
                draggable: "True",
                value: 128,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ea6\u82bd",
                symbolSize: 10,
                draggable: "True",
                value: 322,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u86c7\u8393",
                symbolSize: 3,
                draggable: "True",
                value: 23,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4ed9\u9e64\u8349",
                symbolSize: 3,
                draggable: "True",
                value: 23,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7a7f\u5c71\u7532",
                symbolSize: 8,
                draggable: "True",
                value: 239,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8840\u4f59\u70ad",
                symbolSize: 4,
                draggable: "True",
                value: 52,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8840\u4f59",
                symbolSize: 4,
                draggable: "True",
                value: 52,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u69d0\u82b1",
                symbolSize: 5,
                draggable: "True",
                value: 87,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5730\u80a4\u5b50",
                symbolSize: 4,
                draggable: "True",
                value: 55,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8354\u679d\u6838",
                symbolSize: 3,
                draggable: "True",
                value: 30,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e7f\u8338",
                symbolSize: 6,
                draggable: "True",
                value: 95,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f9f\u677f\u80f6",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8334\u9999",
                symbolSize: 5,
                draggable: "True",
                value: 84,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c0f\u8334\u9999",
                symbolSize: 5,
                draggable: "True",
                value: 84,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4fa7\u67cf",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8336\u6cb9",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e7f\u89d2",
                symbolSize: 10,
                draggable: "True",
                value: 363,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e7f\u89d2\u971c",
                symbolSize: 6,
                draggable: "True",
                value: 123,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u516d\u66f2",
                symbolSize: 4,
                draggable: "True",
                value: 40,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9752\u76ae",
                symbolSize: 7,
                draggable: "True",
                value: 135,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u69df\u6994",
                symbolSize: 6,
                draggable: "True",
                value: 89,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u590f\u5929\u65e0",
                symbolSize: 1,
                draggable: "True",
                value: 5,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u706b\u9ebb\u4ec1",
                symbolSize: 4,
                draggable: "True",
                value: 36,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8109\u901a",
                symbolSize: 5,
                draggable: "True",
                value: 56,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7518\u677e",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8377\u53f6",
                symbolSize: 8,
                draggable: "True",
                value: 205,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f99\u80c6",
                symbolSize: 9,
                draggable: "True",
                value: 269,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e01\u9999",
                symbolSize: 5,
                draggable: "True",
                value: 68,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u82f1",
                symbolSize: 6,
                draggable: "True",
                value: 102,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u534a\u8fb9\u83b2",
                symbolSize: 6,
                draggable: "True",
                value: 112,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8f66\u524d\u8349",
                symbolSize: 6,
                draggable: "True",
                value: 123,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8611\u83c7",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u70ae\u59dc",
                symbolSize: 8,
                draggable: "True",
                value: 185,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5730\u9cd6\u866b",
                symbolSize: 6,
                draggable: "True",
                value: 125,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c99\u53c2",
                symbolSize: 11,
                draggable: "True",
                value: 427,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8d6d\u77f3",
                symbolSize: 8,
                draggable: "True",
                value: 217,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4ee3\u8d6d\u77f3",
                symbolSize: 8,
                draggable: "True",
                value: 202,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e7f\u8854\u8349",
                symbolSize: 6,
                draggable: "True",
                value: 116,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u53cc\u82b1",
                symbolSize: 5,
                draggable: "True",
                value: 60,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e7f\u89d2\u80f6",
                symbolSize: 8,
                draggable: "True",
                value: 184,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5e73\u5730\u6728",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u832f\u82d3",
                symbolSize: 6,
                draggable: "True",
                value: 93,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8349\u679c",
                symbolSize: 5,
                draggable: "True",
                value: 60,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8179\u76ae",
                symbolSize: 7,
                draggable: "True",
                value: 146,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u8179\u76ae",
                symbolSize: 7,
                draggable: "True",
                value: 146,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7f8a\u89d2",
                symbolSize: 6,
                draggable: "True",
                value: 93,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6787\u6777\u53f6",
                symbolSize: 7,
                draggable: "True",
                value: 165,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7f9a\u7f8a\u89d2",
                symbolSize: 6,
                draggable: "True",
                value: 93,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5343\u5e74\u5065",
                symbolSize: 8,
                draggable: "True",
                value: 220,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8305\u6839",
                symbolSize: 7,
                draggable: "True",
                value: 140,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u8305\u6839",
                symbolSize: 7,
                draggable: "True",
                value: 140,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u751f\u6652\u53c2",
                symbolSize: 6,
                draggable: "True",
                value: 98,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u901a\u5929",
                symbolSize: 11,
                draggable: "True",
                value: 484,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7518\u9042",
                symbolSize: 10,
                draggable: "True",
                value: 352,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c89\u9999",
                symbolSize: 6,
                draggable: "True",
                value: 118,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u8fde",
                symbolSize: 4,
                draggable: "True",
                value: 42,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77bf\u9ea6",
                symbolSize: 6,
                draggable: "True",
                value: 101,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5317\u67f4\u80e1",
                symbolSize: 7,
                draggable: "True",
                value: 162,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8702\u8721",
                symbolSize: 4,
                draggable: "True",
                value: 51,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ebb\u6cb9",
                symbolSize: 3,
                draggable: "True",
                value: 30,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ea2\u7cd6",
                symbolSize: 5,
                draggable: "True",
                value: 88,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u849c",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6851\u6939",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d6e\u77f3",
                symbolSize: 5,
                draggable: "True",
                value: 66,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7259\u7682",
                symbolSize: 3,
                draggable: "True",
                value: 30,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u69d0\u7c73",
                symbolSize: 6,
                draggable: "True",
                value: 92,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6ed1\u77f3",
                symbolSize: 8,
                draggable: "True",
                value: 239,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d77\u87b5\u86f8",
                symbolSize: 4,
                draggable: "True",
                value: 54,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9999\u6a7c",
                symbolSize: 6,
                draggable: "True",
                value: 96,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81ea\u7136\u94dc",
                symbolSize: 4,
                draggable: "True",
                value: 54,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u8c46\u853b",
                symbolSize: 3,
                draggable: "True",
                value: 30,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u677e\u8282",
                symbolSize: 6,
                draggable: "True",
                value: 89,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8d25\u9171\u8349",
                symbolSize: 4,
                draggable: "True",
                value: 48,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u534a\u67ab\u8377",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e24\u9762\u9488",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9e45\u4e0d\u98df\u8349",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e5d\u91cc\u9999",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c0f\u9a73\u9aa8",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80e1\u6912",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e09\u53c9\u82e6",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u718a\u80c6",
                symbolSize: 3,
                draggable: "True",
                value: 16,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e5d\u9999\u866b",
                symbolSize: 3,
                draggable: "True",
                value: 16,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u83b2\u987b",
                symbolSize: 2,
                draggable: "True",
                value: 15,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5317\u6c99\u53c2",
                symbolSize: 7,
                draggable: "True",
                value: 173,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8c37\u82bd",
                symbolSize: 5,
                draggable: "True",
                value: 75,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82e6\u695d\u76ae",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6842\u5fc3",
                symbolSize: 5,
                draggable: "True",
                value: 79,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7275\u725b\u5b50",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51ac\u8475\u5b50",
                symbolSize: 4,
                draggable: "True",
                value: 50,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u897f\u74dc",
                symbolSize: 3,
                draggable: "True",
                value: 30,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u6241\u8c46",
                symbolSize: 7,
                draggable: "True",
                value: 163,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7682\u89d2\u523a",
                symbolSize: 7,
                draggable: "True",
                value: 174,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7075\u829d",
                symbolSize: 7,
                draggable: "True",
                value: 156,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f99\u8475",
                symbolSize: 4,
                draggable: "True",
                value: 52,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7d2b\u6cb3\u8f66",
                symbolSize: 3,
                draggable: "True",
                value: 30,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u706f\u82af\u8349",
                symbolSize: 5,
                draggable: "True",
                value: 62,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8346\u82a5",
                symbolSize: 3,
                draggable: "True",
                value: 26,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6731\u7802",
                symbolSize: 3,
                draggable: "True",
                value: 23,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5e7f\u6728\u9999",
                symbolSize: 5,
                draggable: "True",
                value: 77,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8513\u8346\u5b50",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u83b1\u83d4\u5b50",
                symbolSize: 4,
                draggable: "True",
                value: 50,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f99\u80c6\u8349",
                symbolSize: 5,
                draggable: "True",
                value: 78,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6728\u9632\u5df1",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51cc\u9704\u82b1",
                symbolSize: 3,
                draggable: "True",
                value: 27,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8d64\u5c0f\u8c46",
                symbolSize: 6,
                draggable: "True",
                value: 100,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u74dc\u840e\u4ec1",
                symbolSize: 5,
                draggable: "True",
                value: 69,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u9ec4\u70ad",
                symbolSize: 6,
                draggable: "True",
                value: 98,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51ac\u74dc\u76ae",
                symbolSize: 6,
                draggable: "True",
                value: 98,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u86af\u8693",
                symbolSize: 4,
                draggable: "True",
                value: 44,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6241\u84c4",
                symbolSize: 2,
                draggable: "True",
                value: 15,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8bc3\u5b50",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u69b4",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u69b4\u76ae",
                symbolSize: 3,
                draggable: "True",
                value: 31,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u7f8a\u89d2",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u846b\u82a6",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9f9f\u7532",
                symbolSize: 4,
                draggable: "True",
                value: 50,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5546\u9646",
                symbolSize: 4,
                draggable: "True",
                value: 39,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u4e39",
                symbolSize: 4,
                draggable: "True",
                value: 48,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u621f",
                symbolSize: 4,
                draggable: "True",
                value: 36,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82ab\u82b1",
                symbolSize: 4,
                draggable: "True",
                value: 36,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8c61\u8d1d",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6851\u76ae",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u853b",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u853b\u4ec1",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u832f\u795e",
                symbolSize: 2,
                draggable: "True",
                value: 15,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5408\u6b22\u76ae",
                symbolSize: 6,
                draggable: "True",
                value: 91,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5f53\u836f",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8001\u9e73\u8349",
                symbolSize: 4,
                draggable: "True",
                value: 36,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u89c1\u7a7f",
                symbolSize: 4,
                draggable: "True",
                value: 48,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ddd\u519b",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6912\u76ee",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u59dc\u76ae",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u524d",
                symbolSize: 5,
                draggable: "True",
                value: 71,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u751f\u59dc\u76ae",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u516d\u6708\u96ea",
                symbolSize: 5,
                draggable: "True",
                value: 80,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d77\u9a6c",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u901a\u8349",
                symbolSize: 4,
                draggable: "True",
                value: 50,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c71\u5948",
                symbolSize: 4,
                draggable: "True",
                value: 50,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82a6\u835f",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u9644\u5b50",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5343\u91cc\u5149",
                symbolSize: 0,
                draggable: "True",
                value: 0,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u787c\u7802",
                symbolSize: 2,
                draggable: "True",
                value: 15,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e4c\u8d3c\u9aa8",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u82cf\u53f6",
                symbolSize: 5,
                draggable: "True",
                value: 59,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6728\u901a",
                symbolSize: 5,
                draggable: "True",
                value: 70,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7c73\u4ec1",
                symbolSize: 4,
                draggable: "True",
                value: 45,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u94f6\u8033",
                symbolSize: 4,
                draggable: "True",
                value: 40,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767d\u53c2",
                symbolSize: 4,
                draggable: "True",
                value: 41,
                category: "acu_dosages",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6d8c\u6cc9",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 390,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u819d\u5173",
                symbolSize: 29,
                symbol: "diamond",
                draggable: "True",
                value: 4739,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9c7c\u9645",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 336,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u795e\u95e8",
                symbolSize: 15,
                symbol: "diamond",
                draggable: "True",
                value: 947,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5408\u8c37",
                symbolSize: 25,
                symbol: "diamond",
                draggable: "True",
                value: 3296,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u59d4\u4e2d",
                symbolSize: 19,
                symbol: "diamond",
                draggable: "True",
                value: 1738,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u652f\u6c9f",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 189,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8db3\u4e09\u91cc",
                symbolSize: 32,
                symbol: "diamond",
                draggable: "True",
                value: 6159,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u624b\u4e09\u91cc",
                symbolSize: 18,
                symbol: "diamond",
                draggable: "True",
                value: 1460,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c3a\u6cfd",
                symbolSize: 17,
                symbol: "diamond",
                draggable: "True",
                value: 1219,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u4e95",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 147,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6781\u6cc9",
                symbolSize: 16,
                symbol: "diamond",
                draggable: "True",
                value: 1081,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e0a\u5de8\u865a",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 287,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9633\u9675\u6cc9",
                symbolSize: 26,
                symbol: "diamond",
                draggable: "True",
                value: 3467,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u66f2\u6c60",
                symbolSize: 23,
                symbol: "diamond",
                draggable: "True",
                value: 2543,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e09\u9634\u4ea4",
                symbolSize: 27,
                symbol: "diamond",
                draggable: "True",
                value: 3795,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9634\u4ea4",
                symbolSize: 27,
                symbol: "diamond",
                draggable: "True",
                value: 3900,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5185\u5173",
                symbolSize: 22,
                symbol: "diamond",
                draggable: "True",
                value: 2402,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c34\u6c9f",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 652,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9634\u5e02",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 153,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e09\u95f4",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 60,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5ec9\u6cc9",
                symbolSize: 18,
                symbol: "diamond",
                draggable: "True",
                value: 1397,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e0a\u5ec9",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 589,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7167\u6d77",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 717,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5b8c\u9aa8",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 184,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e18\u589f",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 194,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ff3\u98ce",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 245,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80be\u4fde",
                symbolSize: 22,
                symbol: "diamond",
                draggable: "True",
                value: 2305,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5173\u5143",
                symbolSize: 21,
                symbol: "diamond",
                draggable: "True",
                value: 2073,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7387\u8c37",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 40,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51b2\u9633",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 81,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u66f2\u6cc9",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 461,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8840\u6d77",
                symbolSize: 24,
                symbol: "diamond",
                draggable: "True",
                value: 3014,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e30\u9686",
                symbolSize: 19,
                symbol: "diamond",
                draggable: "True",
                value: 1733,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u592a\u6eaa",
                symbolSize: 15,
                symbol: "diamond",
                draggable: "True",
                value: 1016,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u795e\u5ead",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 719,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81bb\u4e2d",
                symbolSize: 15,
                symbol: "diamond",
                draggable: "True",
                value: 921,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u767e\u4f1a",
                symbolSize: 20,
                symbol: "diamond",
                draggable: "True",
                value: 1919,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u795e\u9053",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 151,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u60ac\u9885",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 40,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u51b2",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 40,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u809d\u4fde",
                symbolSize: 16,
                symbol: "diamond",
                draggable: "True",
                value: 1089,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5fc3\u4fde",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 385,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u53a5\u9634\u4fde",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 60,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u89e3\u6eaa",
                symbolSize: 16,
                symbol: "diamond",
                draggable: "True",
                value: 1158,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u60ac\u949f",
                symbolSize: 14,
                symbol: "diamond",
                draggable: "True",
                value: 738,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4f0f\u5154",
                symbolSize: 14,
                symbol: "diamond",
                draggable: "True",
                value: 849,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u592a\u51b2",
                symbolSize: 19,
                symbol: "diamond",
                draggable: "True",
                value: 1687,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5916\u5173",
                symbolSize: 17,
                symbol: "diamond",
                draggable: "True",
                value: 1327,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9634\u9675\u6cc9",
                symbolSize: 21,
                symbol: "diamond",
                draggable: "True",
                value: 2086,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6881\u4e18",
                symbolSize: 21,
                symbol: "diamond",
                draggable: "True",
                value: 2096,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u67a2",
                symbolSize: 18,
                symbol: "diamond",
                draggable: "True",
                value: 1459,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80c3\u4fde",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 359,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u813e\u4fde",
                symbolSize: 17,
                symbol: "diamond",
                draggable: "True",
                value: 1327,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80a9\u4e95",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 609,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81c2\u81d1",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 180,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80a9\u9ac3",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 60,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9644\u5206",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 130,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u6ce8",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 124,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80ba\u4fde",
                symbolSize: 14,
                symbol: "diamond",
                draggable: "True",
                value: 737,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e0b\u8118",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 171,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u8118",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 729,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6ed1\u8089\u95e8",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 395,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5916\u9675",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 280,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u6a2a",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 597,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c14\u6d77",
                symbolSize: 20,
                symbol: "diamond",
                draggable: "True",
                value: 1847,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c34\u5206",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 361,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u795e\u9619",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 601,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5173\u5143\u4fde",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 68,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u80a0\u4fde",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 204,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5efa\u91cc",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 149,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8180\u80f1\u4fde",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 80,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u690e",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 670,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u957f\u5f3a",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 132,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u728a\u9f3b",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 636,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u627f\u6276",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 100,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u73af\u8df3",
                symbolSize: 18,
                symbol: "diamond",
                draggable: "True",
                value: 1395,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u627f\u5c71",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 365,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6bb7\u95e8",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 252,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u677c",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 71,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u6e1a",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 129,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6881\u95e8",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 330,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9690\u767d",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u516c\u5b59",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 200,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8170\u9633\u5173",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 183,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8188\u4fde",
                symbolSize: 15,
                symbol: "diamond",
                draggable: "True",
                value: 934,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9633\u6eaa",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 166,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9633\u6c60",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 316,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9633\u8c37",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 102,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u592a\u767d",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 93,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ae0\u95e8",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 147,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5185\u5ead",
                symbolSize: 10,
                symbol: "diamond",
                draggable: "True",
                value: 324,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4eba\u7687",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 45,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80be\u5173",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 45,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u671f\u95e8",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 198,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80a9\u8d1e",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 309,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u54d1\u95e8",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 259,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6606\u4ed1",
                symbolSize: 16,
                symbol: "diamond",
                draggable: "True",
                value: 1038,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u524d\u9876",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 81,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6b63\u8425",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 547,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5730\u4ed3",
                symbolSize: 14,
                symbol: "diamond",
                draggable: "True",
                value: 775,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8db3\u4e34\u6ce3",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 182,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4f1a\u9634",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 40,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6b21\u9ace",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 69,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u6781",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 647,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u98ce\u5e9c",
                symbolSize: 14,
                symbol: "diamond",
                draggable: "True",
                value: 827,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c34\u9053",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 224,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u901a\u91cc",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 202,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u988a\u8f66",
                symbolSize: 13,
                symbol: "diamond",
                draggable: "True",
                value: 652,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u98ce\u5e02",
                symbolSize: 15,
                symbol: "diamond",
                draggable: "True",
                value: 968,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u819d\u9633\u5173",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 418,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u818f\u8093",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 126,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u98ce\u95e8",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 105,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8fce\u9999",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 134,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e0a\u661f",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 87,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e73\u6839",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 18,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81ba\u7a97",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 18,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5730\u673a",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 149,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u590d\u6e9c",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 29,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u66f2\u9aa8",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 29,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4eac\u95e8",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u547d\u95e8",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 290,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5934\u7ef4",
                symbolSize: 2,
                symbol: "diamond",
                draggable: "True",
                value: 10,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e0b\u5173",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 209,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4eba\u8fce",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 162,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u7a81",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 485,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u59d4\u9633",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 292,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5408\u9633",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 54,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9634\u8c37",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 269,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u5b97",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 552,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u9675",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 94,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8155\u9aa8",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 206,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7f3a\u76c6",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 71,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u6cc9",
                symbolSize: 8,
                symbol: "diamond",
                draggable: "True",
                value: 194,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u52b3\u5bab",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 98,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c14\u51b2",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 100,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5f3a\u95f4",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 66,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8111\u6237",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 66,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9ac0\u5173",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 257,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5217\u7f3a",
                symbolSize: 9,
                symbol: "diamond",
                draggable: "True",
                value: 278,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u627f\u6d46",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 96,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80a9\u9ace",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 81,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u504f\u5386",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 30,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5f52\u6765",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 30,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u592a\u4e59",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 128,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u5de8",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 90,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u60ac\u5398",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 139,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e09\u7126\u4fde",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 28,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8170\u4fde",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 65,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9634\u90c4",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 50,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u5ead",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6512\u7af9",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 120,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u77f3\u5173",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u5e9c",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 91,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9633\u8f85",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u5c01",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 161,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5546\u4e18",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 76,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u56db\u767d",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 101,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u79e9\u8fb9",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 101,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6761\u53e3",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 32,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9633\u767d",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 50,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u627f\u6ce3",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 50,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u540e\u6eaa",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 529,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u66f2\u6cfd",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 123,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u9f0e",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8179\u7ed3",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 101,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8dd7\u9633",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81d1\u4fde",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u775b\u660e",
                symbolSize: 12,
                symbol: "diamond",
                draggable: "True",
                value: 516,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7533\u8109",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 159,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4ea4\u4fe1",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 76,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c45\u9ace",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u624b\u4e94\u91cc",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 60,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u80c6\u4fde",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 450,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u592a\u6e0a",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 123,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u8fce",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 51,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u90fd",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 50,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u81f3\u9633",
                symbolSize: 7,
                symbol: "diamond",
                draggable: "True",
                value: 175,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8093\u4fde",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 86,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5149\u660e",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4fa0\u6eaa",
                symbolSize: 5,
                symbol: "diamond",
                draggable: "True",
                value: 81,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7b4b\u7f29",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 25,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u60ac\u67a2",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 25,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u810a\u4e2d",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 25,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e2d\u67a2",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 96,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9676\u9053",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 25,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7075\u53f0",
                symbolSize: 3,
                symbol: "diamond",
                draggable: "True",
                value: 25,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8db3\u4e94\u91cc",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 37,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u98de\u626c",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9633\u4ea4",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c0f\u6d77",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7b95\u95e8",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u66f2\u57a3",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e94\u67a2",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5c11\u6d77",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 91,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5fd7\u5ba4",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6c14\u6d77\u4fde",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7ef4\u9053",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7b51\u5bbe",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u4e0b\u5ec9",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5927\u8d6b",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 41,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u66f2\u9b13",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 409,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u98a7\u9ace",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u9894\u538c",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5de8\u9ace",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7075\u9053",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 50,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u95f4\u4f7f",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 50,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7d2b\u5bab",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 50,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8821\u6c9f",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 35,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u90c4\u95e8",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 100,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5934\u4e34\u6ce3",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5916\u4e18",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u65e5\u6708",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u517b\u8001",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 40,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u8098\u9ace",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 40,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u51b2\u95e8",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u627f\u5149",
                symbolSize: 6,
                symbol: "diamond",
                draggable: "True",
                value: 98,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u627f\u7b4b",
                symbolSize: 0,
                symbol: "diamond",
                draggable: "True",
                value: 0,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u5929\u67f1",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 450,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u7136\u8c37",
                symbolSize: 4,
                symbol: "diamond",
                draggable: "True",
                value: 33,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u6276\u7a81",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 409,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
              {
                name: "\u672c\u795e",
                symbolSize: 11,
                symbol: "diamond",
                draggable: "True",
                value: 409,
                category: "acu_points",
                label: {
                  normal: {
                    show: "True",
                  },
                },
              },
            ],
            categories: [
              {},
              {
                name: "diseases",
              },
              {
                name: "prescriptions",
              },
              {
                name: "acu_dosages",
              },
              {
                name: "acu_points",
              },
            ],
            edgeLabel: {
              show: false,
              position: "top",
              margin: 8,
            },
            edgeSymbol: [null, null],
            edgeSymbolSize: 10,
            links: [
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u725b\u819d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u72ec\u6d3b",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u9ebb\u9ec4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u674f\u4ec1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u82a5\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u6b3e\u51ac\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u767d\u82a5\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u9c7c\u8165\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u4e94\u5473\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u9644\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u7ec6\u8f9b",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u9644\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u9ec4\u82aa",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u6843\u4ec1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u515a\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u8d64\u828d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u6842\u679d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u767d\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u4e09\u4e03",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u8476\u82c8\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u5e72\u59dc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9e21\u8840\u85e4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5ddd\u828e",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6843\u4ec1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5730\u9f99",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8d64\u828d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u94a9\u85e4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u725b\u819d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5929\u9ebb",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5927\u9ec4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u82cd\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9ec4\u8fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u819d\u5173",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u8db3\u4e09\u91cc",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u9634\u9675\u6cc9",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u8840\u6d77",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u9633\u9675\u6cc9",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u6881\u4e18",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u59d4\u4e2d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u728a\u9f3b",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u66f2\u6c60",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u4e09\u9634\u4ea4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u9634\u4ea4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u80be\u4fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u592a\u51b2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u5927\u677c",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u6c14\u6d77",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u8170\u9633\u5173",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u8188\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u809d\u4fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u5173\u5143",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u4e09\u95f4",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u5408\u8c37",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u60ac\u949f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u6606\u4ed1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u819d\u9633\u5173",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u4f0f\u5154",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u9c7c\u9645",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u66f2\u6cc9",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u5929\u67a2",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u98ce\u5e02",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u819d\u9aa8\u5173\u8282\u708e",
                target: "\u5730\u673a",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u8db3\u4e09\u91cc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u80be\u4fde",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u5173\u5143",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u4e09\u9634\u4ea4",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u9634\u4ea4",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u81bb\u4e2d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u80ba\u4fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u5927\u690e",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u5929\u7a81",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u66f2\u6c60",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u6c14\u6d77",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u592a\u6e0a",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u795e\u95e8",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5\u80be\u75c5",
                target: "\u8db3\u4e09\u91cc",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u8db3\u4e09\u91cc",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u4e09\u9634\u4ea4",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u8840\u6d77",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u9634\u4ea4",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u7ae0\u95e8",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u592a\u51b2",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u4e2d\u5ead",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u81bb\u4e2d",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u6881\u4e18",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u5929\u7a81",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9c7c\u9645",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5408\u8c37",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u59d4\u4e2d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8db3\u4e09\u91cc",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u624b\u4e09\u91cc",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5c3a\u6cfd",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5929\u4e95",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6781\u6cc9",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9633\u9675\u6cc9",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u66f2\u6c60",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e09\u9634\u4ea4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9634\u4ea4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5185\u5173",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6c34\u6c9f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5ec9\u6cc9",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e0a\u5ec9",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u7167\u6d77",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e18\u589f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u7ff3\u98ce",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u80be\u4fde",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5173\u5143",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u66f2\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8840\u6d77",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e30\u9686",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u592a\u6eaa",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u795e\u5ead",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u81bb\u4e2d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u767e\u4f1a",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u809d\u4fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u89e3\u6eaa",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u60ac\u949f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4f0f\u5154",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u592a\u51b2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5916\u5173",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5929\u67a2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u80c3\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u795e\u95e8",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6d8c\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5fc3\u4fde",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u813e\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u80a9\u4e95",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u81c2\u81d1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u80a9\u9ac3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u957f\u5f3a",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9633\u6c60",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u80a9\u8d1e",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u54d1\u95e8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6606\u4ed1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u524d\u9876",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6b63\u8425",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u73af\u8df3",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5730\u4ed3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8db3\u4e34\u6ce3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u98ce\u5e9c",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u901a\u91cc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u988a\u8f66",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u98ce\u5e02",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6c14\u6d77",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u795e\u9619",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8fce\u9999",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8188\u4fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5929\u5b97",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9634\u9675\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5927\u9675",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8170\u9633\u5173",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u627f\u5c71",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6bb7\u95e8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u9634\u5e02",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5927\u80a0\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8155\u9aa8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u7f3a\u76c6",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5929\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5217\u7f3a",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u627f\u6d46",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u80a9\u9ace",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u819d\u5173",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u60ac\u5398",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u540e\u6eaa",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8dd7\u9633",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u81d1\u4fde",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u775b\u660e",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u7533\u8109",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4ea4\u4fe1",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5c45\u9ace",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u80c6\u4fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5927\u6a2a",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e2d\u5c01",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5149\u660e",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u66f2\u9b13",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e2d\u67a2",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5c11\u6d77",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u5929\u67f1",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u6276\u7a81",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u4e2d\u6781",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u672c\u795e",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e0b\u8118",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5929\u67a2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e2d\u8118",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6ed1\u8089\u95e8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8db3\u4e09\u91cc",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5916\u9675",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e30\u9686",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5927\u6a2a",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6c14\u6d77",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5173\u5143",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6c34\u5206",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e09\u9634\u4ea4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u9634\u9675\u6cc9",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u9634\u4ea4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u80be\u4fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5927\u80a0\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8840\u6d77",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5efa\u91cc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u80c3\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u66f2\u6c60",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u813e\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6881\u95e8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u795e\u95e8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u9633\u9675\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5408\u8c37",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u652f\u6c9f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5185\u5ead",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u592a\u51b2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5185\u5173",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e0a\u5de8\u865a",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u671f\u95e8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8db3\u4e34\u6ce3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8188\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u809d\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5730\u673a",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u592a\u6eaa",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4f0f\u5154",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u9ac0\u5173",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u592a\u4e59",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5927\u5de8",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u81bb\u4e2d",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8179\u7ed3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5927\u690e",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u592a\u6e0a",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6c34\u9053",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e0a\u5ec9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u51b2\u9633",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u66f2\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u66f2\u6cfd",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5927\u9675",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u98de\u626c",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u9633\u4ea4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5c0f\u6d77",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8170\u9633\u5173",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u7b95\u95e8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u66f2\u57a3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e94\u67a2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5c11\u6d77",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5fd7\u5ba4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e2d\u5e9c",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u79e9\u8fb9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6c14\u6d77\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6606\u4ed1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u80c6\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u73af\u8df3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u98ce\u5e02",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u9634\u8c37",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8093\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5929\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u7ef4\u9053",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u60ac\u949f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u59d4\u4e2d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u7b51\u5bbe",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u9633\u6c60",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u4e0b\u5ec9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u5927\u8d6b",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u624b\u4e09\u91cc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u6781\u6cc9",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8155\u9aa8",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u819d\u5173",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u8db3\u4e09\u91cc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u5927\u690e",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u66f2\u6c60",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u80be\u4fde",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u813e\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u6c14\u6d77",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u89e3\u6eaa",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u5408\u8c37",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u6606\u4ed1",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u592a\u6eaa",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u5c3a\u6cfd",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u5916\u5173",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u809d\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u8170\u4fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u592a\u51b2",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u59d4\u4e2d",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u9633\u6c60",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u66f2\u6cfd",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u819d\u9633\u5173",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u81c2\u81d1",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u9633\u6eaa",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u6d8c\u6cc9",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u540e\u6eaa",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u80c3\u4fde",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u7b4b\u7f29",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u8170\u9633\u5173",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u60ac\u67a2",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u810a\u4e2d",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u4e2d\u67a2",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u795e\u9053",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u9676\u9053",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u547d\u95e8",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u7075\u53f0",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u7c7b\u98ce\u6e7f\u6027\u5173\u8282\u708e",
                target: "\u81f3\u9633",
                lineStyle: {
                  width: 0,
                },
              },
              {
                source: "\u6162\u6027\u963b\u585e\u6027\u80ba\u75be\u75c5",
                target: "\u52a0\u5473\u6e29\u80c6\u6c64",
                lineStyle: {
                  width: 1,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u81ea\u62df\u4e2d\u836f\u65b9",
                lineStyle: {
                  width: 1,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u590d\u65b9\u4e39\u53c2",
                lineStyle: {
                  width: 4,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u82d3\u6842\u672f\u7518\u6c64",
                lineStyle: {
                  width: 3,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                lineStyle: {
                  width: 10,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u6842\u679d\u832f\u82d3\u4e38",
                lineStyle: {
                  width: 3,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u8840\u5e9c\u9010\u7600\u6c64",
                lineStyle: {
                  width: 4,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                lineStyle: {
                  width: 3,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u6843\u7ea2\u56db\u7269\u6c64",
                lineStyle: {
                  width: 8,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u51a0\u5fc3\u75c5\u5fc3\u7ede\u75db",
                target: "\u76ca\u6c14\u6d3b\u8840\u6cd5",
                lineStyle: {
                  width: 2,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5\u80be\u75c5",
                target: "\u516d\u5473\u5730\u9ec4\u6c64",
                lineStyle: {
                  width: 1,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5\u80be\u75c5",
                target: "\u6d88\u6e34\u65b9",
                lineStyle: {
                  width: 1,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u964d\u7cd6\u80f6\u56ca",
                lineStyle: {
                  width: 4,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u516d\u5473\u5730\u9ec4\u6c64",
                lineStyle: {
                  width: 2,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                lineStyle: {
                  width: 2,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u81ea\u62df\u4e2d\u836f\u65b9",
                lineStyle: {
                  width: 2,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u7cd6\u5c3f\u75c5",
                target: "\u516d\u5473\u5730\u9ec4\u4e38",
                lineStyle: {
                  width: 6,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u8865\u9633\u8fd8\u4e94\u6c64",
                lineStyle: {
                  width: 10,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                lineStyle: {
                  width: 4,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u963f\u6258\u4f10\u4ed6\u6c40",
                lineStyle: {
                  width: 3,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u590d\u65b9\u4e39\u53c2",
                lineStyle: {
                  width: 10,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u8111\u6897\u6b7b",
                target: "\u845b\u6839\u7d20",
                lineStyle: {
                  width: 2,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u80a5\u80d6",
                target: "\u8840\u8102\u5eb7\u80f6\u56ca",
                lineStyle: {
                  width: 2,
                  color: "#57A0A7",
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u7ea2\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u9ec4\u82aa",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u8089\u6842",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u732a\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u6c99\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u751f\u5730\u9ec4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u9ebb\u9ec4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u5e72\u59dc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u8f66\u524d\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u9ea6\u51ac",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u9ec4\u8fde",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u77e5\u6bcd",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u4e4c\u6885",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u82cd\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u91d1\u94f6\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u94f6\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u845b\u6839",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u77f3\u659b",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u5c71\u8331\u8438",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u4e2d\u836f\u65b9",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 6,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u7384\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u4e09\u4e03",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u901a\u5929",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u8292\u785d",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u8d64\u828d",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u82cd\u672f",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u76ca\u6bcd\u8349",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u5927\u9ec4",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u590d\u65b9\u4e39\u53c2",
                target: "\u9ec4\u8fde",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u9ec4\u82aa",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u515a\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u9e21\u8840\u85e4",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5ddd\u828e",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5f53\u5f52\u5c3e",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u6843\u4ec1",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u571f\u9cd6\u866b",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5730\u9f99",
                lineStyle: {
                  width: 5,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5f52\u5c3e",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u8d64\u828d",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u4e09\u4e03",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u90c1\u91d1",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5929\u9ebb",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u94a9\u85e4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 4,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5927\u67a3",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u67af\u8349",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5357\u661f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u751f\u5730\u9ec4",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u9e9d\u9999",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5927\u9ec4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u590f\u67af\u8349",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5168\u874e",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u6c34\u86ed",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5ddd\u725b\u819d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5929\u7afa\u9ec4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u725b\u819d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u9ea6\u51ac",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u845b\u6839",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u7384\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u6728\u9999",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u5c71\u836f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u50f5\u8695",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u9ec4\u67cf",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u9ec4\u8fde",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u6c99\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8865\u9633\u8fd8\u4e94\u6c64",
                target: "\u751f\u59dc",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                target: "\u6842\u679d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                target: "\u767d\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u901a\u5fc3\u7edc\u80f6\u56ca",
                target: "\u4e09\u4e03",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u4e38",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u8476\u82c8\u5b50",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u767d\u672f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u4eba\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u76ca\u6bcd\u8349",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u524d\u80e1",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u9ec4\u82aa",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u82d3\u6842\u672f\u7518\u6c64",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u4e4c\u68a2\u86c7",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u5357\u661f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u83d6\u84b2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u77f3\u83d6\u84b2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u5730\u9f99",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u5929\u9ebb",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u767d\u828d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u6c34\u86ed",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u767d\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u6cfd\u6cfb",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u5ddd\u828e",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u963f\u6258\u4f10\u4ed6\u6c40",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6842\u679d\u832f\u82d3\u4e38",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6842\u679d\u832f\u82d3\u4e38",
                target: "\u6843\u4ec1",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6842\u679d\u832f\u82d3\u4e38",
                target: "\u6842\u679d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6842\u679d\u832f\u82d3\u4e38",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6842\u679d\u832f\u82d3\u4e38",
                target: "\u8d64\u828d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u964d\u7cd6\u80f6\u56ca",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u964d\u7cd6\u80f6\u56ca",
                target: "\u5929\u82b1\u7c89",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u964d\u7cd6\u80f6\u56ca",
                target: "\u82cd\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u964d\u7cd6\u80f6\u56ca",
                target: "\u845b\u6839",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u964d\u7cd6\u80f6\u56ca",
                target: "\u9ec4\u82aa",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u964d\u7cd6\u80f6\u56ca",
                target: "\u592a\u5b50\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8840\u8102\u5eb7\u80f6\u56ca",
                target: "\u5929\u51ac",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u67f4\u80e1",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u725b\u819d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u6843\u4ec1",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u5e72\u59dc",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u67b3\u58f3",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u6854\u6897",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u751f\u5730\u9ec4",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u767d\u672f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u767d\u828d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u8476\u82c8\u5b50",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u8840\u5e9c\u9010\u7600\u6c64",
                target: "\u9644\u5b50",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u8d1d\u6bcd",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u5929\u9ebb",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u6c34\u86ed",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u94a9\u85e4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u5ddd\u8d1d\u6bcd",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u5411\u524d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u845b\u6839",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u9648\u76ae",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u725b\u819d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u845b\u6839\u7d20",
                target: "\u5ddd\u8d1d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                target: "\u6843\u4ec1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                target: "\u515a\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u76ca\u6c14\u6d3b\u8840\u6c64",
                target: "\u6842\u679d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u5927\u67a3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u674f\u4ec1",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u67b3\u5b9e",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u6854\u6897",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u751f\u59dc",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u9648\u76ae",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u52a0\u5473\u6e29\u80c6\u6c64",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u6843\u4ec1",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u767d\u828d",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u719f\u5730",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u7ea2\u82b1",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u5ddd\u828e",
                lineStyle: {
                  width: 3,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u4e09\u4e03",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u4e94\u5473\u5b50",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u8089\u6842",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u515a\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u85a4\u767d",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u7ea2\u53c2",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u534a\u590f",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u5e72\u59dc",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u6843\u7ea2\u56db\u7269\u6c64",
                target: "\u9648\u76ae",
                lineStyle: {
                  width: 2,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u4e39\u76ae",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u719f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u6cfd\u6cfb",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u5c71\u836f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u5c71\u8331\u8438",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u82cd\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u83b2\u987b",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u4eba\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u845b\u6839",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u7384\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u9ec4\u7cbe",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u5929\u82b1\u7c89",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u8d64\u828d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u592a\u5b50\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u9ec4\u67cf",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u516d\u5473\u5730\u9ec4\u6c64",
                target: "\u9ea6\u51ac",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u4e94\u5473\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u82cd\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u8d64\u828d",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u719f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u5f53\u5f52",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u5929\u82b1\u7c89",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u5c71\u836f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u9644\u5b50",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u76ca\u6bcd\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u7384\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u7518\u8349",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u81ea\u62df\u964d\u7cd6\u6d3b\u8840\u65b9",
                target: "\u9ea6\u51ac",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u751f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u4e39\u76ae",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u832f\u82d3",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u82cd\u672f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u719f\u5730",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u83b2\u987b",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u4e39\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u4eba\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u5c71\u836f",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u6cfd\u6cfb",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u845b\u6839",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u5c71\u8331\u8438",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u5730\u9ec4",
                lineStyle: {
                  width: 1,
                },
              },
              {
                source: "\u6d88\u6e34\u65b9",
                target: "\u7384\u53c2",
                lineStyle: {
                  width: 1,
                },
              },
            ],
          },
        ],
        legend: [
          {
            data: [
              "",
              "diseases",
              "prescriptions",
              "acu_dosages",
              "acu_points",
            ],
            selected: {
              "": true,
              diseases: true,
              prescriptions: true,
              acu_dosages: true,
              acu_points: true,
            },
            show: false,
            padding: 5,
            itemGap: 10,
            itemWidth: 25,
            itemHeight: 14,
          },
        ],
        tooltip: {
          show: true,
          trigger: "item",
          triggerOn: "mousemove|click",
          axisPointer: {
            type: "line",
          },
          showContent: true,
          alwaysShowContent: false,
          showDelay: 0,
          hideDelay: 100,
          textStyle: {
            fontSize: 14,
          },
          borderWidth: 0,
          padding: 5,
        },
        title: [
          {
            text: "\u4e2d\u533b\u836f\u6587\u732e\u81ea\u52a8\u5206\u6790\u56fe\u8c31",
            padding: 5,
            itemGap: 10,
          },
        ],
      }
      return option
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.initECharts()
  },
};
</script>
<style scoped>
/* @import url(); 引入css类 */
#echarts {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
}
#main-echarts {
  background-color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
