<template>
  <el-row>
    <el-col :span="20">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        ellipsis="true"
        mode="horizontal"
        background-color="#fff"
        text-decoration:none
        text-color="#333"
        active-text-color="#29BB9C"
        @select="handleSelect"
      >
        <div class="logo-container">
          <el-image
            class="brandArea"
            :src="brandImg"
            style="width: 30px; height: 30px; padding-right: 20px"
          ></el-image>
          <!-- <el-col :span="1"></el-col> -->
          <el-menu-item index="1"
            ><router-link to="/home">首页</router-link></el-menu-item
          >
          <el-menu-item index="2"
            ><router-link to="/upload">上传文献</router-link></el-menu-item
          >
          <el-menu-item index="3"
            ><router-link to="/search">文献数据库</router-link></el-menu-item
          >
          <el-menu-item index="4"
            ><router-link to="/myfile">我的文献</router-link></el-menu-item
          >
          <el-menu-item index="6"
            ><router-link to="/metamap"
              >Meta 分析图谱</router-link
            ></el-menu-item
          >
        </div>
      </el-menu>
    </el-col>
    <el-col :span="4">
      <el-menu
        class="el-menu-right"
        mode="horizontal"
        background-color="#fff"
        text-decoration:none
        text-color="#333"
        active-text-color="#29BB9C"
        @select="handleSelect"
      >
        <template v-if="!verifyLogin()">
          <el-menu-item index="5">
            <router-link to="/account/login">登录</router-link>
          </el-menu-item>
          <el-menu-item index="5">
            <router-link to="/account/register">注册</router-link>
          </el-menu-item>
        </template>
        <template v-else>
          <el-submenu index="5">
            <template slot="title"
              ><img :src="avater()" style="width: 40px; height: 40px"
            /></template>
            <el-menu-item index="5-1"
              ><router-link to="/account/modify"
                >修改密码</router-link
              ></el-menu-item
            >
            <el-menu-item index="5-2" @click="offLogin()"
              >退出登录</el-menu-item
            >
          </el-submenu>
        </template>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
// import axios from "axios"
import { getCookie, delCookie } from "../assets/js/cookie.js"

export default {
  name: "navBar",
  inject: ["reload"],
  methods: {
    verifyLogin: function () {
      let token = getCookie("token")
      console.log("token = ", token)
      if (token == "") {
        return false
      }

      return true
    },
    avater () {
      let username = window.sessionStorage.getItem("mobile")
      if (username) {
        let number = username.charAt(username.length - 1)
        return require("../assets/images/avatars/" + number + ".png")
      } else {
        return require("../assets/images/avatars/0.png")
      }
    },
    offLogin () {
      console.log("退出登录")
      delCookie("token")
      this.reload()
      this.$router.push({ path: "/home" })

      // const that = this
      // axios.delete()
      // axios({
      //   method: "delete",
      //   url: "http://http://81.70.100.28:5000/api/v1.0/logout",
      // }).then(function (response) {
      //   console.log(response)
      //   delCookie("token")
      //   that.reload()
      // })
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
  },
  data () {
    return {
      activeIndex: "1",
      brandImg: require("../assets/images/home-badge.png"),
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
};
</script>

<style scoped lang="less">
/* @import url(); 引入css类 */
.navbar-wrapper {
  // padding: 0 40px;
}
.logo-container {
  display: flex;
  align-items: center;
}
.el-header {
  padding: 0;
}
.el-menu {
  margin: 0;
  padding: 0;
  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.brandArea {
  margin-left: 32px;
}
</style>
