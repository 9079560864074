<!--  -->
<template>
  <div class="search" style="">
    <navBar></navBar>
    <div class="search-center">
      <h2>中医药文献数据库</h2>
      <el-input
        placeholder="请输入文章名称、方剂药物或干预措施等关键词进行检索"
        v-model="keywords"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchClick"
        ></el-button>
      </el-input>
      <div class="search-filter">
        <div class="search-filter-sub">
          <span style="line-height: 38px"> 作者： </span>
          <el-input
            v-model="authors"
            placeholder="请输入内容"
            style="width: 120px"
          ></el-input>
        </div>
        <div class="search-filter-sub">
          <span style="line-height: 38px">病种： </span>
          <template>
            <el-select
              v-model="disease"
              placeholder="请选择"
              style="width: 120px"
            >
              <el-option
                v-for="item in diseases_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </template>
        </div>
        <div class="search-filter-sub">
          <span style="line-height: 38px">发表时间： </span>
          <el-date-picker
            style="width: 120px"
            v-model="pub_year"
            type="year"
            format="yyyy"
            value-format="yyyy"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      keywords: "",
      disease: "",
      pub_year: "",
      authors: "",
      diseases_options: [
        {
          value: "肥胖症",
          label: "肥胖症",
        },
        {
          value: "糖尿病",
          label: "糖尿病",
        },
        {
          value: "脑卒中",
          label: "脑卒中",
        },
        {
          value: "心脏病",
          label: "心脏病",
        },
        {
          value: "风湿性关节炎",
          label: "风湿性关节炎",
        },
        {
          value: "膝骨关节炎",
          label: "膝骨关节炎",
        },
      ],
      diseases_value: "",
    }
  },
  methods: {
    searchClick () {
      var query = {}
      query["keywords"] = this.keywords
      query["pub_year"] = this.pub_year
      query["disease"] = this.disease

      this.$router.push({
        path: "/search/result",
        query: query,
      })
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
};
</script>
<style scoped>
.search {
  height: 100%;
}

h2 {
  line-height: 150%;
  font-size: 36px;
  padding: 20px auto;
}
/* @import url(); 引入css类 */
.search-center {
  max-width: 800px;
  width: 700px;
  height: 300px;

  position: fixed;

  left: 50%; /*元素最左边离窗口左边50%的距离*/
  top: 50%; /*元素最上边离窗口顶部上边50%的距离*/
  margin-top: -150px;
  margin-left: -350px;
}

.search-filter-sub {
  vertical-align: top;
  float: left;
  width: 33.33%;
  box-sizing: border-box;
}

.search-filter {
  margin-top: 30px;
}
</style>
