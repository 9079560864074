<template>
  <div id="app">
    <router-view v-if="isReloadAlive" />
  </div>
</template>

<script>
export default {
  name: "app",
  provide () {
    // 实现页面刷新
    return {
      reload: this.reload,
    }
  },
  data () {
    return {
      isReloadAlive: true,
    }
  },
  methods: {
    reload () {
      this.isReloadAlive = false
      this.$nextTick(() => {
        this.isReloadAlive = true
      })
    },
  },
};
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
