<!--  -->
<template>
  <div class="metafile" clearfix>
    <navBar></navBar>
    <div class="metafile-container">
      <el-row>
        <el-col :span="12">
          <h2>Meta 分析图谱</h2>
          <div id="echarts">
            <div id="main-echarts"></div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="info-extra" style="margin: 20px">
            <h2>信息抽取</h2>
            <div class="info-extra-table" :style="{ height: contentHeight }">
              <el-table
                :data="pubTableData1"
                border
                stripe
                style="width: 100%"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              >
                <el-table-column prop="title" label="出版信息" />
                <el-table-column prop="detail" label="" width="500" />
              </el-table>
              <el-table
                :data="commonTableData"
                border
                stripe
                style="width: 100%"
                :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
              >
                <el-table-column prop="title" label="一般信息" />
                <el-table-column prop="detail" label="" width="500" />
              </el-table>
              <template v-for="(group_item, index) in groupsData">
                <el-table
                  :key="index"
                  :data="group_item.infos"
                  border
                  stripe
                  style="width: 100%"
                  :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                  }"
                >
                  <el-table-column prop="title" :label="group_item.name">
                  </el-table-column>
                  <el-table-column prop="detail" label="" width="500" />
                </el-table>
              </template>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import axios from "axios"
import { getCookie } from "../assets/js/cookie.js"

export default {
  data () {
    return {
      keywords: this.$route.query.keywords,
      file_id: this.$route.query.file_id,
      contentHeight: "300px",
      pubTableData1: [],
      commonTableData: [],
      groupsData: [],
      option: {},
      pubNameMap: {
        title: "标题",
        Book_num: "书籍编号",
        CH_num: "刊号",
        abstract: "摘要",
        authors: "作者",
        hospital_source: "单位",
        file_id: "文件编号",
        pub_year: "年份",
        ref_id: "文献索引号",
        address: "地址",
      },
      commonNameMap: {
        acu_form: "中医干预类型",
        acu_medicine: "中药",
        acu_point: "针灸穴位",
        blindness: "盲法",
        complaint: "病人主诉",
        disease: "病种",
        exclusion_std: "排除标准",
        fund: "基金",
        // groups: "",
        include_std: "纳入标准",
        keywords_ch: "中文关键词",
        keywords_en: "英文关键词",
        patient_source: "病人来源",
        rob_allocation: "rob 分配",
        rob_random: "rob 随机方法",
        setting: "类别",
        tcm_disease: "中医病名",
        wm_disease: "西医病名",
      },
      groupNameMap: {
        acu_points: "针灸穴位",
        age: "年龄",
        course: "病程",
        dosage_form: "药物剂型",
        dosage_freq: "药物频次",
        dosage_names: "药物名称",
        efficacy: "有效率",
        mean_age: "平均年龄",
        mean_course: "平均病程",
        name: "分组名称",
        num_total: "分组数量",
        prescription: "方剂名称",
        treat_freq: "干预频次",
        treat_period: "疗程",
        treat_period_num: "疗程数",
        treatmeat: "干预措施",
      },
    }
  },
  methods: {
    getGraphData () {
      const that = this
      let token = getCookie("token")
      if (token == "") {
        alert("还未登录")
        return
      }

      let data = {
        keywords: this.keywords,
        page_index: 1,
        page_size: 20,
      }
      console.log("data 1 = " + data)
      axios({
        method: "post",
        url: "http://81.70.100.28:5000/api/v1.0/metafile/table2graph",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        data: data,
      }).then(function (response) {
        if (response.data.errno == "0") {
          var data = response.data.data
          that.option = data
          that.initECharts()
        } else {
          alert("请求失败！")
        }
      })
    },
    initECharts: function () {
      var mySize = document.getElementById("echarts")
      // 给宽度 -270 是取合适自己盒子的值
      mySize.style.width = window.innerWidth - 40 + "px"
      // 给高度 -650 是取合适自己盒子的值
      mySize.style.height = window.innerHeight - 101 + "px"

      this.contentHeight = window.innerHeight - 101 + "px"

      let myChart = echarts.init(document.getElementById("main-echarts"))
      myChart.setOption(this.option)
      // 跟着缩放
      window.addEventListener("resize", function () {
        myChart.resize() //自适应大小
      })
    },

    getData () {
      const that = this
      let token = getCookie("token")
      if (token == "") {
        alert("还未登录")
        return
      }
      var file_id = this.file_id
      axios({
        method: "get",
        url: "http://81.70.100.28:5000/api/v1.0/metafile/" + file_id + "/data",
        headers: {
          token: token,
        },
      }).then(function (response) {
        if (response.data.errno == "0") {
          console.log(response.data.data)
          var data = response.data.data

          if (data.title != null) {
            that.file_title = data.title
            that.pubTableData1.push({
              title: "标题",
              detail: data.title,
            })
          }
          if (data.authors != null) {
            that.pubTableData1.push({
              title: "作者",
              detail: data.authors,
            })
          }
          if (data.hospital_source != null) {
            that.pubTableData1.push({
              title: "单位",
              detail: data.hospital_source,
            })
          }
          if (data.pub_year != null) {
            that.pubTableData1.push({
              title: "年份",
              detail: data.pub_year,
            })
          }
          if (data.ref_id != null) {
            that.pubTableData1.push({
              title: "文献索引号",
              detail: data.ref_id,
            })
          }
          if (data.abstract != null) {
            that.pubTableData1.push({
              title: "摘要",
              detail: data.abstract,
            })
          }

          for (const key in data) {
            var detail = data[key]
            if (detail != null && key in that.commonNameMap) {
              that.commonTableData.push({
                title: that.commonNameMap[key],
                detail: detail,
              })
            }
          }
          for (const group_i of data.groups) {
            var group_item = {}
            group_item["name"] = group_i.name
            var group_item_infos = []
            for (const key in group_i) {
              var item_detail = group_i[key]
              if (
                item_detail != null &&
                item_detail != "" &&
                key in that.groupNameMap
              ) {
                group_item_infos.push({
                  title: that.groupNameMap[key],
                  detail: item_detail,
                })
              }
            }
            group_item["infos"] = group_item_infos
            that.groupsData.push(group_item)
          }
        } else {
          alert("请求失败！")
        }
      })
    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {
    this.getData()
    this.getGraphData()
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () { },
};
</script>
<style scoped>
/* @import url(); 引入css类 */

#echarts {
  margin: 20px;
  width: calc(50% - 40px);
  height: calc(100% - 40px);
  text-align: center;
}
#main-echarts {
  background-color: #fff;
  width: 50%;
  height: 100%;
  text-align: center;
}
</style>
